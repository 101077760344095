import Api from "@/services/api";
import { ActionContext } from "vuex";
// import router from "@/router";
import {
  getAllPmlocals,
  getSinglePmlocal,
  searchPmlocals,
  getAllCurrencies,
  getAllExpertsNonPaginated,
  getAllLanguagesNonPaginated,
  getAllClientRate,
  getSingleClientRate,
  getAllBudgetDefinition,
} from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    allPmLocals: [],
    currentPmLocal: {},
    currencies: [],
    tabId: 0,
    tableHeaders: [
      "Project Title",
      "Office Title",
      "Cost Center",
      "Country",
      "Person on Site",
      "Period",
    ],
    allExperts: [],
    allLanguages: [],
    allClientRate: [],
    budgetDefinitions: [],
    singleClientRate: {},
    pmLocalTitle: "",
    pmLocalID: "",
    pmLocalUpdate: {},
    appLoading: false,
    officeTitle: "",
    foreign_establishment_id: null,
    bankCashData: [],
    pmLocalSubId: null,
    allBankCash: [],
    project_id: null,
    showRequestNewFund: false,
    showApproveFund: false,
    allCurrency: [],
    curencyData: [],
    exchangeCurrency: null,
    singleExchangeRate: [],
    exchangeFile: [],
    navigatedFromTodo: false,
    navigatingToWhichTab: null,
  },

  mutations: {
    SET_PM_LOCALS_TO_STATE(state: any, payload: any) {
      state.allPmLocals = payload;
    },
    SET_ALL_EXPERTS_TO_STATE(state: any, payload: any) {
      state.allExperts = payload;
    },
    SET_ALL_LANGUAGES_TO_STATE(state: any, payload: any) {
      state.allLanguages = payload;
    },
    SET_CURRENCIES_TO_STATE(state: any, payload: any) {
      state.currencies = payload;
    },
    SET_SINGLE_CLIENT_RATE_TO_STATE(state: any, payload: any) {
      state.singleClientRate = payload;
    },
    SET_CLIENT_RATE_TO_STATE(state: any, payload: any) {
      state.allClientRate = payload;
    },
    SET_BUDGET_DEFINITION_TO_STATE(state: any, payload: any) {
      state.budgetDefinitions = payload;
    },

    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },

    SET_PM_LOCAL_ID_STATE(state: any, payload: any) {
      state.pmLocalID = payload;
    },
    SET_PM_LOCAL_TITLE_STATE(state: any, payload: any) {
      state.pmLocalTitle = payload;
    },
    SET_CURRENT_PM_LOCAL_TO_STATE(state: any, payload: any) {
      state.currentPmLocal = payload;
    },
    SET_PM_LOCAL_UPDATE(state: any, payload: any) {
      state.pmLocalUpdate = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CHECK_ACCEPTANCE_TO_STATE(state: any, payload: any) {
      state.checkAcceptance = payload;
    },
    SET_SHOW_ADD_NEW_FUND(state: any, payload: boolean) {
      state.showAddNewFund = payload;
    },
    SET_SHOW_REQUEST_NEW_FUND(state: any, payload: boolean) {
      state.showRequestNewFund = payload;
    },
    SET_SHOW_APPROVE_FUND(state: any, payload: boolean) {
      state.showApproveFund = payload;
    },
    SET_FOREIGN_ESTABLISH_ID_TO_STATE(state: any, payload: any) {
      state.foreign_establishment_id = payload;
    },
    SET_PROJECT_ID_TO_STATE(state: any, payload: any) {
      state.project_id = payload;
    },
    SET_ALL_BANK_CASH_TO_STATE(state: any, payload: any) {
      state.bankCashData = payload;
    },
    SET_PM_LOCAL_SUB_ID_STATE(state: any, payload: any) {
      state.pmLocalSubId = payload;
    },
    SET_ALL_BANK_CASH_ITEM_TO_STATE(state: any, payload: any) {
      state.allBankCash = payload;
    },
    SET_ALL_CURRENCY_ITEM_TO_STATE(state: any, payload: any) {
      state.allCurrency = payload;
    },
    SET_ALL_CURRENCY_TO_STATE(state: any, payload: any) {
      state.curencyData = payload;
    },
    SET_CURRENCY_EXCHANGE_ID_TO_STATE(state: any, payload: any) {
      state.exchangeCurrency = payload;
    },
    SET_SINGLE_EXCHANGE_RATE_TO_STATE(state: any, payload: any) {
      state.singleExchangeRate = payload;
    },
    SET_EXCHANGE_FILES_TO_STATE(state: any, payload: any) {
      state.exchangeFile = payload;
    },

    SET_NAVIGATED_FROM_TODO_TO_STATE(state: any, payload: boolean) {
      state.navigatedFromTodo = payload;
    },
    SET_NAVIGATING_TO_WHICH_TAB_TO_STATE(state: any, payload: any) {
      state.navigatingToWhichTab = payload;
    },
  },
  getters: {
    isCurrentTab(state: any) {
      return state.tabId;
    },

    currentPmLocal(state: any) {
      return state.currentPmLocal;
    },
    appLoading(state: any) {
      return state.appLoading;
    },

    allPmLocals(state: any) {
      return state.allPmLocals;
    },
    allBudgetDefinitions(state: any) {
      return state.budgetDefinitions;
    },

    allCurrencies(state: any) {
      return state.currencies;
    },
    singleClientRate(state: any) {
      return state.singleClientRate;
    },
    allExperts(state: any) {
      return state.allExperts;
    },
    allLanguages(state: any) {
      return state.allLanguages;
    },
    allClientRates(state: any) {
      return state.allClientRate;
    },

    tableHeaders(state: any) {
      return state.tableHeaders;
    },

    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },

    isDisabled(state: any) {
      return state.disabled;
    },

    isPmLocalID(state: any) {
      return state.pmLocalID;
    },
    isPmLOcalTitle(state: any) {
      return state.pmLocalTitle;
    },
    isPmLocalUpdate(state: any) {
      return state.pmLocalUpdate;
    },
    isCheckAcceptance(state: any) {
      return state.checkAcceptance;
    },
    isForeignEstablishmentID(state: any) {
      return state.foreign_establishment_id;
    },
    isBankCashData(state: any) {
      return state.bankCashData;
    },
    isPmlocalSubId(state: any) {
      return state.pmLocalSubId;
    },
    isAllBankCash(state: any) {
      return state.allBankCash;
    },
    isProjectId(state: any) {
      return state.currentPmLocal.project_id;
    },
    getShowRequestNewFund(state: any) {
      return state.showRequestNewFund;
    },
    getShowApproveFund(state: any) {
      return state.showApproveFund;
    },
    isCurrencyData(state: any) {
      return state.curencyData;
    },
    isAllCurrency(state: any) {
      return state.allCurrency;
    },
    isExchangeCurrency(state: any) {
      return state.exchangeCurrency;
    },
    isSingleExchangeRate(state: any) {
      return state.singleExchangeRate;
    },
    isExchangeFile(state: any) {
      return state.exchangeFile;
    },
    getNavigatedFromTodo(state: any) {
      return state.navigatedFromTodo;
    },
    getNavigatingToWhichTab(state: any) {
      return state.navigatingToWhichTab;
    },
  },
  actions: {
    getAllPmlocals(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getAllPmlocals}${payload.page_number}/`)
        .then((response: any) => {
          if (response) {
            context.commit("SET_PM_LOCALS_TO_STATE", response.data.data.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.data.total_data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          // if(error.response.status === 313){
          //   router.push({ name: "accessDenied" });
          // }
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllBudgetDefinition(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getAllBudgetDefinition}${context.getters.isPmLocalID}/`)
        .then((response: any) => {
          if (response) {
            context.commit(
              "SET_BUDGET_DEFINITION_TO_STATE",
              response.data?.data?.budget
            );

            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getAllCurrencies(context: ActionContext<any, any>) {
      Api()
        .get(`${getAllCurrencies}${context.getters.isPmLocalID}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_CURRENCIES_TO_STATE", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSingleClientRate(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(
          `${getSingleClientRate}${context.getters.isPmLocalID}/${payload.year}/${payload.month}`
        )
        .then((response: any) => {
          if (response.data.status === 1) {
            context.commit(
              "SET_SINGLE_CLIENT_RATE_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllExpertsNonPaginated(context: ActionContext<any, any>) {
      Api()
        .get(`${getAllExpertsNonPaginated}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_EXPERTS_TO_STATE", response.data.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getAllLanguagesNonPaginated(context: ActionContext<any, any>) {
      Api()
        .get(`${getAllLanguagesNonPaginated}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_LANGUAGES_TO_STATE", response.data.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getAllClientRate(context: ActionContext<any, any>) {
      Api()
        .get(`${getAllClientRate}${context.getters.isPmLocalID}/`)
        .then((response: any) => {
          if (response.data.status === 1) {
            context.commit("SET_CLIENT_RATE_TO_STATE", response.data.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getSinglePmlocal(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getSinglePmlocal}${payload.pmLocalID}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_CURRENT_PM_LOCAL_TO_STATE", responseData.data);
            context.commit(
              "SET_CHECK_ACCEPTANCE_TO_STATE",
              responseData.data.cheque_acceptance
            );
            context.commit(
              "SET_FOREIGN_ESTABLISH_ID_TO_STATE",
              responseData.data.foreign_establishment
            );
            context.commit(
              "SET_PROJECT_ID_TO_STATE",
              responseData.data.project_id
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_PM_LOCAL_SUB_ID_STATE", responseData.data.id);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    searchPmlocals(context: ActionContext<any, any>, payload: any) {
      Api()
        .post(`${searchPmlocals}1/`, payload)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_PM_LOCALS_TO_STATE", response.data.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
