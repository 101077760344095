<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1755 3.22225C12.1766 1.99445 9.67307 1 6.58832 1C3.50357 1 1.00224 1.99557 1 3.22225M1 3.22225C1 4.45004 3.50133 5.44449 6.58832 5.44449C9.6753 5.44449 12.1766 4.45004 12.1766 3.22225L12.1766 11.8445M1 3.22225V16.5556C1.00112 17.7834 3.50245 18.7779 6.58832 18.7779C8.08487 18.7779 9.43612 18.5412 10.4387 18.1601M1.00112 7.66672C1.00112 8.89451 3.50245 9.88896 6.58944 9.88896C9.67643 9.88896 12.1778 8.89451 12.1778 7.66672M10.5057 13.6946C9.4976 14.0891 8.11505 14.3335 6.58832 14.3335C3.50245 14.3335 1.00112 13.3391 1.00112 12.1113M18.5272 12.4646C20.4909 14.4169 20.4909 17.5836 18.5272 19.5358C16.5635 21.4881 13.3781 21.4881 11.4144 19.5358C9.45065 17.5836 9.45065 14.4169 11.4144 12.4646C13.3781 10.5124 16.5635 10.5124 18.5272 12.4646Z"
      stroke=""
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
