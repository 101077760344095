import { createI18n } from "vue-i18n";

// @ts-ignore
export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  messages: {
    en: require("./locales/en.json"),
    de: require("./locales/de.json")
  },
});
