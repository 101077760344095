<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99935 7.49935V1.66602H14.9993V7.49935M4.99935 14.9993H3.33268C2.89065 14.9993 2.46673 14.8238 2.15417 14.5112C1.84161 14.1986 1.66602 13.7747 1.66602 13.3327V9.16602C1.66602 8.72399 1.84161 8.30007 2.15417 7.9875C2.46673 7.67494 2.89065 7.49935 3.33268 7.49935H16.666C17.108 7.49935 17.532 7.67494 17.8445 7.9875C18.1571 8.30007 18.3327 8.72399 18.3327 9.16602V13.3327C18.3327 13.7747 18.1571 14.1986 17.8445 14.5112C17.532 14.8238 17.108 14.9993 16.666 14.9993H14.9993M4.99935 11.666H14.9993V18.3327H4.99935V11.666Z"
      stroke="white"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
  font-size: 15px;
}
</style>
