<template>
  <div
    class="!bg-[#FFFCF5] outline outline-1 outline-[#FEC84B] mt-1 pl-6 h-[100px] flex items-center"
  >
    <div>
      <div
        class="p-[2px] flex text-sm bg-[#FFFAEB] items-center rounded-lg w-fit"
      >
        <p class="bg-[#DC6803] rounded-lg px-2 text-[#FFFCF5] mr-2">
          {{ $t("employment.contractTerm") }}
        </p>
        <p class="text-[#B54708] mr-2">
          {{ getFormattedDate(isPersonalData?.termination_information?.date) }}
        </p>
        <p class="text-[#B54708] mr-2">
          | {{ isPersonalData?.termination_information?.reason }}
        </p>
      </div>
      <div class="text-sm text-[#DC6803] mt-[12px]">
        <p>
          {{ isPersonalData?.termination_information?.comment }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { formatDate, getTransString } from "@/services/helpers";

export default defineComponent({
  name: "Contract Terminated",
  data() {
    return {};
  },
  computed: {
    isPersonalData() {
      return this.$store.getters["hrResources/isPersonalData"];
    },
  },
  methods: {
    getFormattedDate(date) {
      return formatDate(date);
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
  },
});
</script>
