import Api from "@/services/api";
import { getSettlementForManualInvoice } from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    manualInvoice: {},
    selectFields: [
      {
        key: "cost_centre_id",
        label: "Cost Center",
        placeholder: "Select Cost Center",
      },
      {
        key: "currency",
        label: "Currency",
        placeholder: "Select currency",
      },
      {
        key: "invoice_for",
        label: "Invoice For",
        placeholder: "Enter Invoice For",
      },
      {
        key: "start_date",
        label: "Start Date",
        placeholder: "Select Start Date",
      },
      {
        key: "end_date",
        label: "End Date",
        placeholder: "Select End Date",
      },
    ],
    editManualInvoiceId: null,
    manualInvoiceSettlementData: {},
  },
  getters: {
    getManualInvoice(state: any) {
      return state.manualInvoice;
    },
    getSelectFields: (state: any) => state.selectFields,
    getEditManualInvoiceId: (state: any) => state.editManualInvoiceId,
    getManualInvoiceSettlementData: (state: any) =>
      state.manualInvoiceSettlementData,
  },
  mutations: {
    setManualInvoice(state: any, manualInvoice: any) {
      state.manualInvoice = manualInvoice;
    },
    setEditManualInvoiceId(state: any, editManualInvoiceId: any) {
      state.editManualInvoiceId = editManualInvoiceId;
    },
    setManualInvoiceSettlementData(
      state: any,
      manualInvoiceSettlementData: any
    ) {
      state.manualInvoiceSettlementData = manualInvoiceSettlementData;
    },
  },
  actions: {
    fetchManualInvoiceSettlementData({ commit }: any, manualInvoiceId: any) {
      Api()
        .get(`${getSettlementForManualInvoice}${manualInvoiceId}`)
        .then((res) => {
          commit("setManualInvoiceSettlementData", res.data?.data);
        })
        .catch(() => {
          // console.log(err);
        });
    },
  },
};
