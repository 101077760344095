<template>
  <svg
    width="47"
    height="46"
    viewBox="0 0 47 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3.5" y="3" width="40" height="40" rx="20" fill="#D1FADF" />
    <path
      d="M24.3333 14.6667L16 24.6667H23.5L22.6667 31.3334L31 21.3334H23.5L24.3333 14.6667Z"
      stroke="#039855"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="3.5"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#ECFDF3"
      stroke-width="6"
    />
  </svg>
</template>
