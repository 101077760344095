<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_585_32216)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.06718 3.91089C1.28167 4.93288 0.689365 6.11081 0.344482 7.39046H5.54675L2.06718 3.91089Z"
        fill="#0052B4"
      />
      <path
        d="M19.6558 7.39054C19.3109 6.11093 18.7186 4.933 17.9331 3.91101L14.4536 7.39054H19.6558Z"
        fill="#0052B4"
      />
      <path
        d="M0.344482 12.6085C0.689404 13.8881 1.28171 15.0661 2.06718 16.088L5.54663 12.6085H0.344482Z"
        fill="#0052B4"
      />
      <path
        d="M16.0879 2.06649C15.0659 1.28098 13.888 0.688672 12.6084 0.34375V5.54598L16.0879 2.06649Z"
        fill="#0052B4"
      />
      <path
        d="M3.91162 17.9314C4.93361 18.7169 6.11155 19.3092 7.39116 19.6541V14.4519L3.91162 17.9314Z"
        fill="#0052B4"
      />
      <path
        d="M7.39111 0.34375C6.1115 0.688672 4.93357 1.28098 3.91162 2.06644L7.39111 5.54593V0.34375Z"
        fill="#0052B4"
      />
      <path
        d="M12.6084 19.6541C13.888 19.3092 15.0659 18.7169 16.0879 17.9314L12.6084 14.4519V19.6541Z"
        fill="#0052B4"
      />
      <path
        d="M14.4536 12.6085L17.9331 16.088C18.7186 15.0661 19.3109 13.8881 19.6558 12.6085H14.4536Z"
        fill="#0052B4"
      />
      <path
        d="M19.9154 8.69566H11.3044H11.3044V0.0846484C10.8774 0.0290625 10.4421 0 10 0C9.55785 0 9.12262 0.0290625 8.69566 0.0846484V8.69559V8.69563H0.0846484C0.0290625 9.12262 0 9.55793 0 10C0 10.4421 0.0290625 10.8774 0.0846484 11.3043H8.69559H8.69563V19.9154C9.12262 19.9709 9.55785 20 10 20C10.4421 20 10.8774 19.971 11.3043 19.9154V11.3044V11.3044H19.9154C19.9709 10.8774 20 10.4421 20 10C20 9.55793 19.9709 9.12262 19.9154 8.69566Z"
        fill="#D80027"
      />
      <path
        d="M12.6086 12.6094L17.071 17.0718C17.2762 16.8666 17.472 16.6521 17.6588 16.4298L13.8384 12.6094H12.6086V12.6094Z"
        fill="#D80027"
      />
      <path
        d="M7.39134 12.6094H7.39126L2.92896 17.0717C3.13411 17.2769 3.3486 17.4727 3.57095 17.6595L7.39134 13.839V12.6094Z"
        fill="#D80027"
      />
      <path
        d="M7.39122 7.39093V7.39085L2.92888 2.92847C2.72364 3.13362 2.52786 3.34812 2.34106 3.57046L6.16149 7.39089H7.39122V7.39093Z"
        fill="#D80027"
      />
      <path
        d="M12.6086 7.39187L17.071 2.92945C16.8659 2.72421 16.6514 2.52843 16.429 2.34167L12.6086 6.1621V7.39187Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_585_32216">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
