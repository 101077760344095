<template>
  <svg
    id="dotIcon"
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99984 8.83331C2.46007 8.83331 2.83317 8.46022 2.83317 7.99998C2.83317 7.53974 2.46007 7.16665 1.99984 7.16665C1.5396 7.16665 1.1665 7.53974 1.1665 7.99998C1.1665 8.46022 1.5396 8.83331 1.99984 8.83331Z"
      stroke="#98A2B3"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.99984 2.99998C2.46007 2.99998 2.83317 2.62688 2.83317 2.16665C2.83317 1.70641 2.46007 1.33331 1.99984 1.33331C1.5396 1.33331 1.1665 1.70641 1.1665 2.16665C1.1665 2.62688 1.5396 2.99998 1.99984 2.99998Z"
      stroke="#98A2B3"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.99984 14.6666C2.46007 14.6666 2.83317 14.2935 2.83317 13.8333C2.83317 13.3731 2.46007 13 1.99984 13C1.5396 13 1.1665 13.3731 1.1665 13.8333C1.1665 14.2935 1.5396 14.6666 1.99984 14.6666Z"
      stroke="#98A2B3"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
  font-size: 16px;
}
</style>
