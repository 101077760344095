import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  postContractTaskUrl,
  getAllContractTaskUrl,
  putContractTaskUrl,
  getOneContractTaskUrl,
  deleteContractTaskUrl,
  searchContractTaskUrl,
  getAllEmployeesUrl,
  getAllContractApproversUrl,
  postContractApproversUrl,
  manageAllKeywords,
  manageSingleKeyword,
  filterKeywordsByCategoryId,
  getTerms,
} from "./../../services/endpoints";

interface Payload {
  task_id?: string | number;
  keyword_id?: string | number;
  category_ids?: number[];
  title?: string;
  content?: string;
}

interface Page {
  pageNumber?: string | number;
  searchQuery?: string;
}

interface ApproverPayload {
  first_approver_id: string;
  second_approver_id: string;
}

export default {
  namespaced: true,
  state: {
    error: {},
    response: {},
    contractTasks: [],
    keywords: [],
    employees: [],
    approvers: {},
    isConfigurationsUpdate: {},
    current_page: 1,
    total_data: 1,
    appLoading: false,
    termsAndConditions: [],
    termsId: null,
    singleTermsData: null,
    englishTerms: null,
    frenchTerms: null,
    germanTerms: null,
    spanishTerms: null,
  },
  getters: {
    getError(state: any) {
      return state.error;
    },
    isTotalPages(state: any) {
      return state.total_data;
    },
    isCurrentPage(state: any) {
      return state.current_page;
    },
    getResponse(state: any) {
      return state.response;
    },
    getContractTasks(state: any) {
      return state.contractTasks;
    },
    getKeywords(state: any) {
      return state.keywords;
    },
    getEmployees(state: any) {
      return state.employees;
    },
    getApprovers(state: any) {
      return state.approvers;
    },
    isConfigurationsUpdate(state: any) {
      return state.isConfigurationsUpdate;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isAllTermsAndConditions(state: any) {
      return state.termsAndConditions;
    },
    isTermsId(state: any) {
      return state.termsId;
    },
    isSingleTermsData(state: any) {
      return state.singleTermsData;
    },
    isEnglishTerms(state: any) {
      return state.englishTerms;
    },
    isFrenchTerms(state: any) {
      return state.frenchTerms;
    },
    isGermanTerms(state: any) {
      return state.germanTerms;
    },
    isSpanishTerms(state: any) {
      return state.spanishTerms;
    },
  },
  mutations: {
    SET_ERROR(state: any, data: any) {
      state.error = { ...data };
    },
    SET_RESPONSE(state: any, data: any) {
      state.response = { ...data };
    },
    SET_CONTRACT_TASKS(state: any, data: any) {
      state.contractTasks = data;
    },
    SET_KEYWORDS(state: any, data: any) {
      state.keywords = data;
    },
    REMOVE_KEYWORD(state: any, keywordIdToRemove: number | string) {
      const indexToRemove = state.keywords?.data?.findIndex(
        (task: { id: string | number }) => task.id === keywordIdToRemove
      );
      if (indexToRemove !== -1) {
        state.keywords?.data?.splice(indexToRemove, 1);
      }
    },
    SET_EMPLOYEES(state: any, data: any) {
      state.employees = [...data];
    },
    SET_APPROVERS(state: any, data: any) {
      state.approvers = { ...data };
    },
    SET_CURRENT_PAGE(state: any, data: any) {
      state.current_page = data;
    },
    SET_TOTAL_PAGE(state: any, data: any) {
      state.total_data = data;
    },
    SET_CONFIGURATIONS_UPDATE(state: any, payload: any) {
      state.isConfigurationsUpdate = { ...payload };
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_TERMS_AND_CONDITIONS_TO_STATE(state: any, payload: any) {
      state.termsAndConditions = payload;
    },
    SET_TERMS_ID_TO_STATE(state: any, payload: any) {
      state.termsId = payload;
    },
    SET_SINGLE_TERMS_DATA_TO_STATE(state: any, payload: any) {
      state.singleTermsData = payload;
    },
    SET_ENGLISH_TERMS_TO_STATE(state: any, payload: any) {
      state.englishTerms = payload;
    },
    SET_FRENCH_TERMS_TO_STATE(state: any, payload: any) {
      state.frenchTerms = payload;
    },
    SET_GERMAN_TERMS_TO_STATE(state: any, payload: any) {
      state.germanTerms = payload;
    },
    SET_SPANISH_TERMS_TO_STATE(state: any, payload: any) {
      state.spanishTerms = payload;
    },
  },
  actions: {
    async getAllContractTasks(
      { commit }: ActionContext<any, any>,
      payload: Page
    ) {
      try {
        const response = await Api().get(
          `${getAllContractTaskUrl}${payload.pageNumber}`
        );

        commit("SET_CONTRACT_TASKS", response.data.data);
        commit("SET_CURRENT_PAGE", response.data.current_page);
        commit("SET_TOTAL_PAGE", response.data.total_data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_CONTRACT_TASKS", {});
      }
    },

    async createContractTask(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().post(postContractTaskUrl, {
          title: payload.title,
          content: payload.content,
        });
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async updateContractTask(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().put(
          `${putContractTaskUrl}${payload.task_id}/`,
          {
            title: payload.title,
            content: payload.content,
          }
        );
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async deleteContractTask(
      { state, commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().delete(
          `${deleteContractTaskUrl}${payload.task_id}`
        );

        commit("SET_RESPONSE", data.data);
        if (state.response?.status === "success") {
          commit("REMOVE_CONTRACT_TASK", payload.task_id); //remove from store
        }
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async getOneContractTask(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().get(
          `${getOneContractTaskUrl}${payload.task_id}`
        );
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async searchContractTasks(
      { commit }: ActionContext<any, any>,
      payload: Page
    ) {
      try {
        const response = await Api().post(
          `${searchContractTaskUrl}${payload.pageNumber}/`,
          {
            search_query: payload.searchQuery,
          }
        );
        commit("SET_CONTRACT_TASKS", response.data.data);
        commit("SET_CURRENT_PAGE", response.data.current_page);
        commit("SET_TOTAL_PAGE", response.data.total_data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_CONTRACT_TASKS", {});
      }
    },
    async getAllKeywords({ commit }: ActionContext<any, any>, payload: Page) {
      try {
        const response = await Api().get(
          `${manageAllKeywords}${payload.pageNumber}`
        );

        commit("SET_KEYWORDS", response.data.data);
        commit("SET_CURRENT_PAGE", response.data.current_page);
        commit("SET_TOTAL_PAGE", response.data.total_data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_KEYWORDS", {});
      }
    },
    async getAllKeywordsByCategoryId(
      { commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const response = await Api().post(
          `${filterKeywordsByCategoryId}`,
          payload
        );

        commit("SET_KEYWORDS", response.data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_KEYWORDS", []);
      }
    },
    async createKeyword({ commit }: ActionContext<any, any>, payload: Payload) {
      try {
        const data = await Api().post(manageAllKeywords, payload);
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async updateKeyword({ commit }: ActionContext<any, any>, payload: Payload) {
      const { keyword_id, ...rest } = payload;
      try {
        const data = await Api().patch(
          `${manageSingleKeyword}${keyword_id}/`,
          rest
        );
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async deleteKeyword(
      { state, commit }: ActionContext<any, any>,
      payload: Payload
    ) {
      try {
        const data = await Api().delete(
          `${manageSingleKeyword}${payload.keyword_id}/`
        );

        commit("SET_RESPONSE", data.data);
        if (state.response?.status === "success") {
          commit("REMOVE_KEYWORD", payload.keyword_id); //remove from store
        }
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async getOneKeyword({ commit }: ActionContext<any, any>, payload: Payload) {
      try {
        const data = await Api().get(
          `${manageSingleKeyword}${payload.keyword_id}`
        );
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async searchKeywords({ commit }: ActionContext<any, any>, payload: Page) {
      try {
        const response = await Api().post(
          `${searchContractTaskUrl}${payload.pageNumber}/`,
          {
            search_query: payload.searchQuery,
          }
        );
        commit("SET_CONTRACT_TASKS", response.data.data);
        commit("SET_CURRENT_PAGE", response.data.current_page);
        commit("SET_TOTAL_PAGE", response.data.total_data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_CONTRACT_TASKS", {});
      }
    },
    async getAllEmployees({ commit }: ActionContext<any, any>) {
      try {
        const data = await Api().get(`${getAllEmployeesUrl}`);

        commit("SET_EMPLOYEES", data?.data?.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_EMPLOYEES", []);
      }
    },

    async createOrUpdateContractApprovers(
      { commit }: ActionContext<any, any>,
      payload: ApproverPayload
    ) {
      try {
        const data = await Api().post(postContractApproversUrl, {
          first_approver_id: payload.first_approver_id,
          second_approver_id: payload.second_approver_id,
        });
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async getAllContractApprovers({ commit }: ActionContext<any, any>) {
      try {
        const data = await Api().get(`${getAllContractApproversUrl}`);
        commit("SET_APPROVERS", data?.data?.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_APPROVERS", {});
      }
    },
    getAllTermsAndConditions(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getTerms}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const modifiedData = response.data.data.map((term: any) => {
              return {
                id: term.id,
                "contract type": term.contract_type,
                "contract language(s)": term.available_languages,
              };
            });
            context.commit("SET_TERMS_AND_CONDITIONS_TO_STATE", modifiedData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSingleTermsAndConditions(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getTerms}${payload.id}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_ENGLISH_TERMS_TO_STATE",
              response.data.data.english
            );
            context.commit(
              "SET_FRENCH_TERMS_TO_STATE",
              response.data.data.french
            );
            context.commit(
              "SET_GERMAN_TERMS_TO_STATE",
              response.data.data.german
            );
            context.commit(
              "SET_SPANISH_TERMS_TO_STATE",
              response.data.data.spanish
            );
            context.commit(
              "SET_SINGLE_TERMS_DATA_TO_STATE",
              response.data.data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
