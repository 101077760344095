<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="-0.0078125" width="32" height="32" rx="16" fill="#F2F4F7" />
    <g clip-path="url(#clip0_30336_149871)">
      <path
        d="M15.9993 10.1562V21.8229M10.166 15.9896H21.8327"
        stroke="#667085"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_30336_149871">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(6 5.99219)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
