// import { createApp } from 'vue'
import { createVuetify, ThemeDefinition } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { customSVGs } from "./customSvgs";
import * as component from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labs from "vuetify/labs/components";
import "@mdi/font/css/materialdesignicons.css";

const customLightTheme: ThemeDefinition = {
  // dark: false,
  // light: false,
  colors: {
    background: "#FCFCFD",
    // surface: '#FFFFFF',
    primary: "#8B2923",
    "primary-darken-1": "#943B36",
    secondary: "#D0D5DD",
    "secondary-darken-1": "#943B36",
    // secondary: "#8B29230A",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};
const customDarkTheme: ThemeDefinition = {
  // dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#FB8C00",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

export default createVuetify({
  directives,
  components: {
    ...component,
    ...labs,
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
  theme: {
    defaultTheme: "customLightTheme",
    themes: {
      customLightTheme,
      customDarkTheme,
    },
  },
});
