export default {
  namespaced: true,
  state: {
    isAccessRoleUpdate: {},
  },
  getters: {
    isAccessRoleUpdate(state: any) {
      return state.isAccessRoleUpdate;
    },
  },
  mutations: {
    SET_ACCESS_ROLE_UPDATE(state: any, payload: any) {
      state.isAccessRoleUpdate = { ...state.isAccessRoleUpdate, ...payload };
    },
  },
  actions: {},
};
