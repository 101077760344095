import {
  costOverview,
  getBankGuaranteeReport,
  costPerformanceOverview,
  getProjectYearsByCostCenter,
  getCostCenterByProjects,
  getBudgetItemDetailsByCostItem,
  openReceivablesEndpoint,
  openInvoiceEndpoint,
} from "@/services/endpoints";
import { ActionContext } from "vuex";
import Api from "@/services/api";

export default {
  namespaced: true,
  state: {
    userViewingDetails: {},
    headers: [
      { key: "cost_centre", label: "Cost Center" },
      { key: "project_title", label: "Project Name" },
      { key: "project_manager", label: "Project Managers", type: "list" },
      { key: "offer_controller", label: "Controller", type: "list" },
      { key: "project_date", label: "Project Start - End Date", type: "date" },
    ],
    currentPage: 1,
    totalPages: null,
    appLoading: false,
    costOverviewAll: [],
    allBankGuarantees: [],
    bankGuaranteeHeaders: [
      { label: "Project Name", key: "project" },
      { label: "Project Start - End Date", key: "project_date" },
      { label: "Contract Id", key: "contract" },
      { label: "Bank Name", key: "bank_name" },
      { label: "Bank Id", key: "bank" },
      { label: "Bank Guarantee Nr", key: "bank_guarantee_nr" },
      { label: "End of Bank Guarantee", key: "end_of_bank_guarantee" },
      { label: "Aval Commission", key: "aval_commission", type: "digit" },
      { label: "Reduction to Bank", key: "reduction_to_bank", type: "digit" },
      { label: "Amount", key: "amount", type: "digit" },
      { label: "Advance", key: "advance" },
      {
        label: "Bank Guarantee Expired",
        key: "bank_guarantee_expiration_status",
      },
    ],
    allCostHeaders: [],
    allCostTableItems: [],
    yearlyCostHeaders: [],
    yarlyTableItems: [],
    costOverviewId: null,
    costProjectTitle: null,
    allAnnualVacationData: [],
    allBreakEvenData: [],
    allBusinessAreaConflictData: [],
    allEmployeeAbsenceData: [],
    allEmployeeMonthData: [],
    allEmployeeRemoteDaysData: [],
    allEmployeeTimeOffStatsData: [],
    allProjectHoursReportData: [],
    allSicknessRateData: [],
    hrReportingCurrentReport: null,
    allCostPerformanceOverviewData: [],
    openReceivablesData: [],
    allProjectYears: [],
    allCostCentersByProject: [],
    allBudgetItemDetailsByCostItem: [],
    openInvoiceData: [],
    planningData: [],
    statusQuoProjectData: [],
    detailedForecastViewItem: {},
    pmToViewDetails: {}
  },

  mutations: {
    setPmToViewDetails(state: any, payload: any) {
        state.pmToViewDetails = payload;
    },
    setDetailedForecastViewItem(state: any, payload: any) {
        state.detailedForecastViewItem = payload;
    },
    setUserViewingDetails(state: any, payload: any) {
      state.userViewingDetails = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_COST_OVERVIEW_ALL_TO_STATE(state: any, payload: any) {
      state.costOverviewAll = payload;
    },
    SET_BANK_GUARANTEES_TO_STATE(state: any, payload: any) {
      state.allBankGuarantees = payload;
    },
    SET_ALL_COST_HEADERS_TO_STATE(state: any, payload: any) {
      state.allCostHeaders = payload;
    },
    SET_ALL_COST_TABLE_ITEMS_TO_STATE(state: any, payload: any) {
      state.allCostTableItems = payload;
    },
    SET_YEARLY_COST_HEADERS_TO_STATE(state: any, payload: any) {
      state.yearlyCostHeaders = payload;
    },
    SET_YEARLY_TABLE_ITEMS_TO_STATE(state: any, payload: any) {
      state.yarlyTableItems = payload;
    },
    SET_COST_OVERVIEW_ID_TO_STATE(state: any, payload: any) {
      state.costOverviewId = payload;
    },
    SET_COST_PROJECT_TITLE_TO_STATE(state: any, payload: any) {
      state.costProjectTitle = payload;
    },
    SET_All_ANNUAL_VACATION_DATA_TO_STATE(state: any, payload: any) {
      state.allAnnualVacationData = payload;
    },
    SET_All_BREAK_EVEN_DATA_TO_STATE(state: any, payload: any) {
      state.allBreakEvenData = payload;
    },
    SET_All_BUSINESS_AREA_CONFLICT_DATA_TO_STATE(state: any, payload: any) {
      state.allBusinessAreaConflictData = payload;
    },
    SET_All_EMPLOYEE_ABSENCE_DATA_TO_STATE(state: any, payload: any) {
      state.allEmployeeAbsenceData = payload;
    },
    SET_All_EMPLOYEE_MONTH_DATA_TO_STATE(state: any, payload: any) {
      state.allEmployeeMonthData = payload;
    },
    SET_All_EMPLOYEE_REMOTE_DAYS_DATA_TO_STATE(state: any, payload: any) {
      state.allEmployeeRemoteDaysData = payload;
    },
    SET_All_EMPLOYEE_TIME_OFF_STATS_DATA_TO_STATE(state: any, payload: any) {
      state.allEmployeeTimeOffStatsData = payload;
    },
    SET_All_PROJECT_HOURS_REPORT_DATA_TO_STATE(state: any, payload: any) {
      state.allProjectHoursReportData = payload;
    },
    SET_All_SICKNESS_RATE_DATA_TO_STATE(state: any, payload: any) {
      state.allSicknessRateData = payload;
    },
    SET_HR_REPORTING_CURRENT_REPORT_TO_STATE(state: any, payload: any) {
      state.hrReportingCurrentReport = payload;
    },
    SET_PERFORMANCE_OVERVIEW_DATA_TO_STATE(state: any, payload: any) {
      state.allCostPerformanceOverviewData = payload;
    },
    SET_OPEN_RECEIVABLES_DATA_TO_STATE(state: any, payload: any) {
      state.openReceivablesData = payload;
    },
    SET_OPEN_INVOICE_DATA_TO_STATE(state: any, payload: any) {
      state.openInvoiceData = payload;
    },
    SET_PROJECT_YEARS(state: any, payload: any) {
      state.allProjectYears = payload;
    },
    SET_COST_CENTERS_BY_PROJECT(state: any, payload: any) {
      state.allCostCentersByProject = payload;
    },
    SET_BUDGET_ITEM_DETAILS_BY_COST_ITEM(state: any, payload: any) {
      state.allBudgetItemDetailsByCostItem = payload;
    },
    SET_PLANNING_DATA(state: any, payload: any) {
      state.planningData = payload;
    },
    SET_STATUS_QUO_PROJECT_DATA(state: any, payload: any) {
      state.statusQuoProjectData = payload;
    },


  },
  getters: {
    getPmToViewDetails: (state: any) => state.pmToViewDetails,
    getDetailedForecastViewItem: (state: any) => state.detailedForecastViewItem,
    getUserViewingDetails: (state: any) => state.userViewingDetails,
    isHeaders: (state: any) => state.headers,
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    totalPages(state: any) {
      return state.totalPages;
    },
    totalData(state: any) {
      return state.totalData;
    },
    appLoading(state: any) {
      return state.appLoading;
    },

    isCostOverviewAll(state: any) {
      return (
        state.costOverviewAll?.map((item: any) => {
          return {
            ...item,
            cost_centre: item.cost_centre ? item.cost_centre : "-",
            offer_controller:
              item.offer_controller
                ?.map(
                  (controller: any) =>
                    `${controller.title} ${controller.first_name} ${controller.last_name}`
                )
                .join(" ") || "-",
            project_manager: item.project_manager ? item.project_manager : [],
            project_date: `${
              item.project_start_date?.formattedDate() || "-"
            } - ${item.project_end_date?.formattedDate() || "-"}`,
          };
        }) || []
      );
    },
    getBankGuaranteeHeaders(state: any) {
      return state.bankGuaranteeHeaders;
    },
    getBankGuarantees(state: any) {
      return state.allBankGuarantees;
    },
    getAllCostHeaders(state: any) {
      return state.allCostHeaders;
    },
    getAllCostTableItems(state: any) {
      return state.allCostTableItems;
    },
    getYearlyCostHeaders(state: any) {
      return state.yearlyCostHeaders;
    },
    getYearlyTableItems(state: any) {
      return state.yarlyTableItems;
    },
    getCostOverviewId(state: any) {
      return state.costOverviewId;
    },
    getCostProjectTitle(state: any) {
      return state.costProjectTitle;
    },
    getAllAnnualVacationData(state: any) {
      return state.allAnnualVacationData?.slice() || [];
    },
    getAllBreakEvenData(state: any) {
      return state.allBreakEvenData?.slice() || [];
    },
    getAllBusinessAreaConflictData(state: any) {
      return state.allBusinessAreaConflictData?.slice() || [];
    },
    getAllEmployeeAbsenceData(state: any) {
      return state.allEmployeeAbsenceData?.slice() || [];
    },
    getAllEmployeeMonthData(state: any) {
      return state.allEmployeeMonthData?.slice() || [];
    },
    getAllEmployeeRemoteDaysData(state: any) {
      return state.allEmployeeRemoteDaysData?.slice() || [];
    },
    getAllEmployeeTimeOffStatsData(state: any) {
      return state.allEmployeeTimeOffStatsData?.slice() || [];
    },
    getAllProjectHoursReportData(state: any) {
      return state.allProjectHoursReportData?.slice() || [];
    },
    getAllSicknessRateData(state: any) {
      return state.allSicknessRateData?.slice() || [];
    },
    getHrReportingCurrentReport(state: any) {
      return state.hrReportingCurrentReport;
    },
    getAllCostPerformanceOverviewData(state: any) {
      return state.allCostPerformanceOverviewData;
    },
    getAllProjectYears(state: any) {
      return state.allProjectYears;
    },
    getAllCostCentersByProject(state: any) {
      return state.allCostCentersByProject;
    },
    getAllBudgetItemDetailsByCostItem(state: any) {
      return state.allBudgetItemDetailsByCostItem;
    },
    getOpenReceivablesData(state: any) {
      return state.openReceivablesData;
    },
    getOpenInvoiceData(state: any) {
      return state.openInvoiceData;
    },
    getPlanningData(state: any) {
      return state.planningData;
    },
    getStatusQuoProjectData(state: any) {
      return state.statusQuoProjectData;
    },
  },
  actions: {
    listCostOverview(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${costOverview}?search=${payload.search}&page_number=${payload.page_number}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_COST_OVERVIEW_ALL_TO_STATE",
              response.data.data
            );
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );

            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getCostOverviewData(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${costOverview}${payload.id}/?year=${payload.year}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            const responseHeaders = response.data.years;
            context.commit("SET_ALL_COST_HEADERS_TO_STATE", responseHeaders);
            context.commit("SET_ALL_COST_TABLE_ITEMS_TO_STATE", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllBankGuarantees(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getBankGuaranteeReport}?search=${payload.search || ""}&company=${
            payload.company || ""
          }&business_area=${payload.business_area || ""}&status=${
            payload.status || ""
          }&bank=${payload.bank || ""}&year=${payload.year || ""}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_BANK_GUARANTEES_TO_STATE", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllCostPerformanceOverview(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${costPerformanceOverview}?cost_center=${
            payload.cost_center || ""
          }&overview_type=${payload.overview_type || ""}&table_type=${
            payload.table_type || ""
          }&year=${payload.year || ""}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
          
            context.commit("SET_PERFORMANCE_OVERVIEW_DATA_TO_STATE", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getOpenReceivables(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${openReceivablesEndpoint}?search=${payload.search}&company=${payload.company}&business_area=${payload.business_area}&project_manager=${payload.project_manager}&controller=${payload.controller}&project=${payload.project}&years=${payload.years}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_OPEN_RECEIVABLES_DATA_TO_STATE", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }else {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getProjectYears(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${getProjectYearsByCostCenter}${payload.cost_center}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_PROJECT_YEARS", responseData);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getCostCenterByProjects(context: ActionContext<any, any>) {
      Api()
        .get(`${getCostCenterByProjects}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_COST_CENTERS_BY_PROJECT", responseData);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getBudgetItemDetailsByCostItem(
      context: ActionContext<any, any>,
      payload: any
    ) {
      Api()
        .get(`${getBudgetItemDetailsByCostItem}${payload.budget_item_id}/?year=${payload.year}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit(
              "SET_BUDGET_ITEM_DETAILS_BY_COST_ITEM",
              responseData
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    openInvoices(context: ActionContext<any, any>, payload: any) {
      console.log("passing.. here in store.....")
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${openInvoiceEndpoint}?search=${payload.search}&company=${payload.company}&business_area=${payload.business_area}&project_manager=${payload.project_manager}&controller=${payload.controller}&project=${payload.project}&years=${payload.years}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_OPEN_INVOICE_DATA_TO_STATE", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          } else {
            
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
