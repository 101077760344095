<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 8.33332H3M13.8333 1.66666V4.99999M7.16667 1.66666V4.99999M7 18.3333H14C15.4001 18.3333 16.1002 18.3333 16.635 18.0608C17.1054 17.8212 17.4878 17.4387 17.7275 16.9683C18 16.4335 18 15.7335 18 14.3333V7.33332C18 5.93319 18 5.23313 17.7275 4.69835C17.4878 4.22794 17.1054 3.84549 16.635 3.60581C16.1002 3.33332 15.4001 3.33332 14 3.33332H7C5.59987 3.33332 4.8998 3.33332 4.36502 3.60581C3.89462 3.84549 3.51217 4.22794 3.27248 4.69835C3 5.23313 3 5.93319 3 7.33332V14.3333C3 15.7335 3 16.4335 3.27248 16.9683C3.51217 17.4387 3.89462 17.8212 4.36502 18.0608C4.8998 18.3333 5.59987 18.3333 7 18.3333Z"
      stroke=""
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
