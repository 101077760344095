import Api from "@/services/api";

export default {
    namespaced: true,
    state: {
        activityExperiences: [],
        donorExperiences: [],
        countries: [],
        regionExperiences: []
    },
    getters: {
        getActivityExperiences: (state: any) => state.activityExperiences,
        getDonorExperiences: (state: any) => state.donorExperiences,
        getRegionExperiences: (state: any) => state.regionExperiences
    },
    mutations: {
        setActivityExperience: (state: any, payload: any) => {
            state.activityExperiences = payload
        },
        setRegionExperience: (state: any, payload: any) => {
            state.regionExperiences = payload
        },
        setDonorExperience: (state: any, payload: any) => {
            state.donorExperiences = payload
        },
        setCountries(state: any, payload: any) {
            state.countries = payload
        }
    },
    actions: {
        getAllActivityExperiences(context: any) {
            Api().get("/configuration/activities/get-all-activities/")
                .then(res => {
                    const newData = res.data?.data.map((activity: any) => ({id: activity.id, text: activity.name, value: activity.name}))
                    context.commit("setActivityExperience", newData)
                })
                .catch(err => {})
        },
        getAllDonorExperiences(context: any) {
            Api().get("/configuration/donor/get-donors/")
                .then(res => {
                    const newData = res.data?.data.map((donor: any) => ({id: donor.id, text: donor.name, value: donor.name}))
                    context.commit("setDonorExperience", newData)
                })
                .catch(err => {})
        },
        getAllRegionExperiences(context: any) {
            Api().get("/configuration/region/get-all-regions/")
                .then((res) => {
                    const newData = res.data?.data.map((region: any) => ({id: region.id, text: region.name, value: region.name}))
                    context.commit("setRegionExperience", newData)
                })
                .catch(err => {})
        }
    },
}