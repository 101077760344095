import { ActionContext } from "vuex";
import Api from "@/services/api";
import { getClientAdvancePayment } from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    headers: [
      "Project Title",
      "Cost Center",
      "Countries",
      "Project Status",
      "Project Phase",
    ],
    appLoading: false,
    currentPage: 1,
    totalPages: null,
    totalData: null,
    advancePaymentClientList: [],
    singleAdvancePaymentClient: [],
    initialSingleAdvancePaymentClient: [],
    projectTitle: "",
    selectedItem: [],
    invoiceNumbers: [],
    companies: [],
    business_areas: [],
    total_planned_amount: "",
    total_requested_amount: "",
    total_received_amount: "",
    addAllToArray: { text: "All", value: "All" },
  },
  mutations: {
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_ADVANCE_PAYMENT_CLIENT_LIST_TO_STATE(state: any, payload: any) {
      state.advancePaymentClientList = payload;
    },
    SET_SINGLE_ADVANCE_PAYMENT_CLIENT_TO_STATE(state: any, payload: any) {
      state.singleAdvancePaymentClient = payload;
    },
    SET_PROJECT_TITLE_TO_STATE(state: any, payload: any) {
      state.projectTitle = payload;
    },
    SET_INITIAL_INTERIM_PAYMENT_TO_STATE(state: any, payload: any) {
      state.initialSingleAdvancePaymentClient = payload;
    },
    SET_SELECTED_ITEM_TO_STATE(state: any, payload: any) {
      state.selectedItem = payload;
    },
    SET_INVOICE_NUMBERS_TO_STATE(state: any, payload: any) {
      state.invoiceNumbers = payload;
    },
    SET_COMPANIES_TO_STATE(state: any, payload: any) {
      state.companies = payload;
    },
    SET_BUSINESS_AREAS_TO_STATE(state: any, payload: any) {
      state.business_areas = payload;
    },
    SET_TOTAL_PLANNED_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_planned_amount = payload;
    },
    SET_TOTAL_REQUESTED_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_requested_amount = payload;
    },
    SET_TOTAL_RECEIVED_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_received_amount = payload;
    },
  },
  getters: {
    isHeaders(state: any) {
      return state.headers;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    totalPages(state: any) {
      return state.totalPages;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isAdvancePaymentClientList(state: any) {
      return state.advancePaymentClientList;
    },
    isSingleAdvancePaymentClient(state: any) {
      return state.singleAdvancePaymentClient;
    },
    isProjectTitle(state: any) {
      return state.projectTitle;
    },
    isInitialSingleAdvancePaymentClient(state: any) {
      return state.initialSingleAdvancePaymentClient;
    },
    isSelectedItem(state: any) {
      return state.selectedItem;
    },
    isInvoiceNumbers(state: any) {
      return state.invoiceNumbers;
    },
    isCompanies(state: any) {
      return state.companies;
    },
    isBusinessAreas(state: any) {
      return state.business_areas;
    },
    isTotalPlannedAmount(state: any) {
      return state.total_planned_amount;
    },
    isTotalRequestedAmount(state: any) {
      return state.total_requested_amount;
    },
    isTotalReceivedAmount(state: any) {
      return state.total_received_amount;
    },
  },
  actions: {
    clientAdvancePaymentList(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_CURRENT_PAGE_TO_STATE", payload.page);
      }
      Api()
        .get(
          `${getClientAdvancePayment}?search=${payload.search}&country=${payload.country}&business_area=${payload.business_area}&company=${payload.company}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            const modifiedData = response.data.data.map((item: any) => {
              return {
                id: item.id,
                "project title": item.project_title,
                "cost center": item.cost_centre,
                countries: item.countries,
                "project status": item.project_status,
                "project phase": item.project_phase,
              };
            });
            context.commit(
              "SET_ADVANCE_PAYMENT_CLIENT_LIST_TO_STATE",
              modifiedData
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
            const getCompanies = response.data.companies.map((item: any) => {
              return {
                text: item.title,
                value: item.id,
              };
            });
            context.commit("SET_COMPANIES_TO_STATE", [
              context.state.addAllToArray,
              ...getCompanies,
            ]);
            const getBusiness = response.data.business_areas.map(
              (item: any) => {
                return {
                  text: item.title,
                  value: item.id,
                };
              }
            );
            context.commit("SET_BUSINESS_AREAS_TO_STATE", [
              context.state.addAllToArray,
              ...getBusiness,
            ]);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getClientAdvancePayment(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getClientAdvancePayment}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const modifiedInvoiceNumber = response.data?.invoices?.map(
              (item: any) => {
                const {
                  invoice_number: text,
                  id: value,
                  date: date,
                  requested_amount: requested_amount,
                  received_amount: received_amount,
                  payment_date: payment_date,
                  difference_amount: difference_amount,
                } = item;
                return {
                  text,
                  value,
                  date,
                  requested_amount,
                  received_amount,
                  payment_date,
                  difference_amount,
                };
              }
            );
            context.commit(
              "SET_SINGLE_ADVANCE_PAYMENT_CLIENT_TO_STATE",
              response.data?.data
            );
            context.commit(
              "SET_INITIAL_INTERIM_PAYMENT_TO_STATE",
              response.data?.data
            );
            context.commit(
              "SET_PROJECT_TITLE_TO_STATE",
              response.data?.project_title
            );
            context.commit(
              "SET_TOTAL_PLANNED_AMOUNT_TO_STATE",
              response.data?.total_planned_amount
            );
            context.commit(
              "SET_TOTAL_REQUESTED_AMOUNT_TO_STATE",
              response.data?.total_requested_amount
            );
            context.commit(
              "SET_TOTAL_RECEIVED_AMOUNT_TO_STATE",
              response.data?.total_received_amount
            );

            context.commit(
              "SET_INVOICE_NUMBERS_TO_STATE",
              modifiedInvoiceNumber
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
