<template>
  <svg
    width="12"
    height="2"
    viewBox="0 0 12 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.9165 1H10.0832"
      stroke="#AE6A65"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
  font-size: 12px;
}
</style>
