import Api from "@/services/api";
import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    isAuthToken: null,
    currentUser: {},
    userPermissions: null,
    token: null,
    refreshToken: null,
    rail: true,
    canEditModuleSections: "",
    done: null,
    showNavMenu: false,
  },

  mutations: {
    SET_AUTH_USER(state: any, payload: any) {
      state.isAuthToken = payload.token;
      state.currentUser = payload.userDetails;
      state.userPermissions = payload.userPermissions;
    },
    CLEAR_CURRENT_USER(state: any) {
      state.isAuthToken = null;
      state.currentUser = null;
      state.userPermissions = null;
    },
    SET_AUTH_USER_INFO(state: any, payload: any) {
      state.currentUser = { ...state.currentUser, ...payload };
    },
    SET_AUTH_TOKEN(state: any, payload: any) {
      state.isAuthToken = payload.token;
    },
    SET_REFRESH_TOKEN(state: any, payload: any) {
      state.refreshToken = payload;
    },
    SET_RAIL_TO_STATE(state: any, payload: any) {
      state.rail = payload;
    },
    SET_CAN_EDIT_TO_STATE(state: any, payload: any) {
      state.canEditModuleSections = payload;
    },
    SET_DONE(state: any, payload: any) {
      state.done = payload;
      state.rail = payload;
    },
    SET_SHOW_NAV_MENU(state: any, payload: any) {
      state.showNavMenu = payload;
    },
  },
  getters: {
    isAuthToken(state: any) {
        return state.isAuthToken;
    },
    isShowNavMenu(state: any) {
        return state.showNavMenu;
    },
    getUsersTodos(state: any) {
      return state.currentUser?.user_to_dos?.slice() || []
    },
    getUndoneTodosCount(state: any) {
        return state.currentUser?.user_to_dos?.filter((todo: any) => !todo.completed).length || 0
    },
    getUsersNotifications(state: any) {
        return state.currentUser?.user_notification?.slice() || []
    },
    getUnreadNotificationCount(state: any) {
      return state.currentUser?.user_notification?.filter((notification: any) => notification.new_flag).length || 0
    },
    getUserAnnouncements(state: any) {
        return state.currentUser?.announcements?.slice() || []
    },
    getUserAnnouncementsCount(state: any) {
        return state.currentUser?.announcements?.length || 0
    },
    getUserIncomingInvoices(state: any) {
      return state.currentUser?.new_invoices?.slice() || []
    },
    getUserIncomingInvoicesCount(state: any) {
      return state.currentUser?.new_invoices?.length || 0
    },
    getUserTeamSchedule(state: any) {
        return state.currentUser?.team_schedule?.slice() || []
    },
    getUserBasicInfo(state: any) {
      return {
        full_name: state.currentUser?.first_name + " " + state.currentUser?.last_name,
        email: state.currentUser?.email,
        username: state.currentUser?.username,
        first_name: state.currentUser?.first_name,
        last_name: state.currentUser?.last_name,
        user_key: state.currentUser?.user_key,
        signature: state.currentUser?.signature,
        profile_picture: state.currentUser?.profile_picture,
        user_type: state.currentUser?.user_type,
      };
    },
    getUserEmploymentInfo(state: any) {
      return {
        remaining_leave_days: state.currentUser?.remaining_leave_days,
        employment_type: state.currentUser?.employment_type,
        team_lead: state.currentUser?.team_leaders,
        position: state.currentUser?.position
      }
    },
    getLeaves(state: any) {
      return {
        "Today": state.currentUser?.on_leave_day,
        "Tomorrow": state.currentUser?.on_leave_tomorrow,
        "Next Week": state.currentUser?.on_leave_next_week
      }
    },
    
    isCanEditModuleSections(state: any){
      return state.canEditModuleSections;
    },
    isRail(state: any) {
      return state.rail;
    },
    isUserDetails(state: any) {
      return state?.currentUser;
    },
    isAuthenticated(state: any) {
      return !!state.isAuthToken;
    },
    isProjectAdmin(state: any) {
      return (
        Object.keys(state.currentUser.is_modules_admin).includes(
          "projects_admin"
        ) && state.currentUser.is_modules_admin.projects_admin
      );
    },
    isTravelAdmin(state: any) {
      const roles = ["Super Admin", "Travel Admin", "Accountant"];
      return (
        roles.includes(state.currentUser.role) ||
        (Object.keys(state.currentUser.is_modules_admin)?.includes(
          "travel_admin"
        ) &&
          state.currentUser.is_modules_admin.travel_admin)
      );
    },
    isCurrentUserEmail(state: any) {
      return state?.currentUser?.email;
    },
    isUsername(state: any) {
      return state?.currentUser?.username;
    },
    role(state: any) {
      return state?.currentUser?.is_admin;
    },
    isControllingModuleAdmin(state: any) {
      return (
        state?.currentUser?.is_admin ||
        state?.currentUser?.role === "Controlling Admin"
      );
    },
    isProjectModuleAdmin(state: any) {
      return (
        state?.currentUser?.is_admin ||
        state?.currentUser?.role === "Projects Admin"
      );
    },
    isSuperModuleAdmin(state: any) {
      return (
        state?.currentUser?.is_admin ||
        state?.currentUser?.role === "Projects Admin" ||
        state?.currentUser?.role === "Controlling Admin"
      );
    },
    isModuleAdmins(state: any) {
      return state?.currentUser?.is_modules_admin;
    },
    isPersonalKey(state: any) {
      return state?.currentUser?.user_key;
    },
    isTeamSchedule(state: any) {
      const modifiedData = state?.currentUser?.team_schedule;
      return modifiedData?.map((data: any) => {
        return {
          name: `${data.first_name} ${data.last_name}`,
          monday: `${
            data?.working_days?.length > 0
              ? data?.working_days[0]?.start_time
              : ""
          }-${
            data?.working_days?.length > 0
              ? data?.working_days[0]?.end_time
              : ""
          }`,
          monday_remote: data?.working_days?.length > 0 ? data?.working_days[0]?.is_remote : "",
          tuesday: `${
            data?.working_days?.length > 0
              ? data?.working_days[1]?.start_time
              : ""
          }-${
            data?.working_days?.length > 0
              ? data?.working_days[1]?.end_time
              : ""
          }`,
          tuesday_remote: data?.working_days?.length > 0 ? data?.working_days[1]?.is_remote : "",
          wednesday: `${
            data?.working_days?.length > 0
              ? data?.working_days[2]?.start_time
              : ""
          }-${
            data?.working_days?.length > 0
              ? data?.working_days[2]?.end_time
              : ""
          }`,
          wednesday_remote: data?.working_days?.length > 0 ? data?.working_days[2]?.is_remote : "",
          thursday: `${
            data?.working_days?.length > 0
              ? data?.working_days[3]?.start_time
              : ""
          }-${
            data?.working_days?.length > 0
              ? data?.working_days[3]?.end_time
              : ""
          }`,
          thursday_remote: data?.working_days?.length > 0 ? data?.working_days[3]?.is_remote : "",
          friday: `${
            data?.working_days?.length > 0
              ? data?.working_days[4]?.start_time
              : ""
          }-${
            data?.working_days?.length > 0
              ? data?.working_days[4]?.end_time
              : ""
          }`,
          friday_remote: data?.working_days?.length > 0 ? data?.working_days[4]?.is_remote : "",
        };
      });
    },
    isAccountant(state: any) {
      return state?.currentUser?.is_accountant || state?.currentUser?.is_modules_admin?.accounting || state.currentUser?.is_modules_admin?.accounting_admin
    },
    ishr(state: any) {
      return state?.currentUser?.is_hr_approver;
    },
    isContractApprover(state: any) {
      return state?.currentUser?.role === "Contract Admin";
    },
    isfullName(state: any) {
      return state?.currentUser?.full_name;
    },
    isCeo(state: any) {
      return state?.currentUser?.is_ceo;
    },
    isController(state: any) {
      return state?.currentUser?.is_controller;
    },
    isEmployee(state: any) {
      return state?.currentUser?.user_type === "Employee";
    },
    isLeaveRole(state: any) {
      return state?.currentUser?.role === "Hr Manger";
    },
    permissions(state: any) {
      return state?.userPermissions;
    },
    token(state: any) {
      return state?.isAuthToken;
    },
    getRefreshToken(state: any) {
      return state?.refreshToken;
    },
    noAccess(state: any) {
      const getAllPermissions = state?.userPermissions;
      const moduleNames = [];
      for (const permission of getAllPermissions) {
        // if accessLevel is 0, dont push to the module names array
        if (permission?.access_level === "0") {
          moduleNames.push({
            module_name: permission?.module__name,
            module_parent_name: permission?.module__parent__name,
          });
        }
      }
      return moduleNames;
    },
    canView(state: any) {
      const getAllPermissions = state?.userPermissions;
      const moduleNames = [];
      for (const permission of getAllPermissions) {
        // if accessLevel is 0, dont push to the module names array
        if (permission?.access_level === "1") {
          moduleNames.push({
            module_name: permission?.module__name,
            module_parent_name: permission?.module__parent__name,
          });
        }
      }
      return moduleNames;
    },
    canEdit(state: any) {
      const getAllPermissions = state?.userPermissions;
      const moduleNames = [];
      for (const permission of getAllPermissions) {
        // if accessLevel is 0, dont push to the module names array
        if (permission?.access_level === "2") {
          moduleNames.push({
            module_name: permission?.module__name,
            module_parent_name: permission?.module__parent__name,
          });
        }
      }
      return moduleNames;
    },
  },
  actions: {
    getUserHomePage(context: ActionContext<any, any>) {
      Api()
        .get(`/auth/get-user-information/`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_AUTH_USER_INFO", response.data.data);
          }
        })
    },
  },
};
