<template>
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 19.166V17.166C17 16.1051 16.5786 15.0877 15.8284 14.3376C15.0783 13.5874 14.0609 13.166 13 13.166H5C3.93913 13.166 2.92172 13.5874 2.17157 14.3376C1.42143 15.0877 1 16.1051 1 17.166V19.166M13 5.16602C13 7.37515 11.2091 9.16602 9 9.16602C6.79086 9.16602 5 7.37515 5 5.16602C5 2.95688 6.79086 1.16602 9 1.16602C11.2091 1.16602 13 2.95688 13 5.16602Z"
      stroke=""
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
