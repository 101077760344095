<template>
  <v-row class="!bg-[#FFFCF5] outline outline-1 outline-[#FEC84B]">
    <v-col cols="1">
      <div class="pt-[6px]">
        <v-btn
          elevation="0"
          color="#DC6803"
          class="cursor-auto !font-[0.875rem] !font-normal rounded-xl text-white text-capitalize"
        >
          Closed
        </v-btn>
      </div>
    </v-col>
    <v-col cols="11" class="text-[#DC6803] !font-[0.875rem] !font-normal">
      <p>
        The organization has conducted a thorough evaluation of various factors,
        including financial constraints, resource limitations, and shifting
        priorities, leading to the difficult decision to close the project.
      </p>
    </v-col>
  </v-row>
</template>
