<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12758_250844)">
      <rect y="0.0839844" width="16" height="16" rx="8" fill="#AE6A65" />
      <path
        d="M10.5 5.58398L5.5 10.584M5.5 5.58398L10.5 10.584"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <rect
      x="0.5"
      y="0.583984"
      width="15"
      height="15"
      rx="7.5"
      stroke="#AE6A65"
    />
    <defs>
      <clipPath id="clip0_12758_250844">
        <rect y="0.0839844" width="16" height="16" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
