import { createApp } from "vue";
import upperFirst from "lodash.upperfirst";
import camelCase from "lodash.camelcase";

// Require in a base component context
const requireComponent = require.context(".", false, /App-[\w-]+\.vue$/);

requireComponent.keys().forEach((filename) => {
  //Get component config
  const componentConfig = requireComponent(filename);

  //Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(filename.replace(/^\.\//, "").replace(/\.\w+$/, ""))
  );

  //Register component globally
  createApp.component(
    componentName,
    componentConfig.default || componentConfig
  );
});
