import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getProjectCalculationBudget,
 
} from "@/services/endpoints";



interface BudgetLineItem {
  id: number | string;
  structure: string;
  position: string;
  number_of_units: number | string;
  amount_per_unit: number | string;
  account: string;
  is_planning: boolean;
  row_index?: unknown;
}


export default {
  namespaced: true,
  state: {
    structure: [
      {text: "BOP - Fixed Fee", value: "BOP - Fixed Fee"},
      {text: "BOP - Against Receipt", value: "BOP - Against Receipt"},
      {text:  "Costs BOP", value:  "Costs BOP"},
      {text:  "Costs", value:  "Costs"},
      {text:  "Cost Internal", value:  "Cost Internal"},
    ],
    bopStructure: ["Costs BOP"],
    account: [
      {text: "4401 Personnel", value: "4401 Personnel"},
      {text: "4201 Travel expenses", value: "4201 Travel expenses"},
      {text: "4901 Other costs", value: "4901 Other costs"},
      {text: "4902 Other costs receipt", value: "4902 Other costs receipt"},
      {text: "4301 Project funds local", value: "4301 Project funds local"},
      {text: "4302 Project funds local receipt", value: "4302 Project funds local receipt"},
    ],
    bankGuarantee: false,
    projectCalculationBudgets: {},
    calculationBudgetSetionId: "",
    budgetData: [],
    budgetSections: {},
    appLoading: false,
    totalProfitMargin: []
   
  },
  mutations: {
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_ALL_PROJECT_BUDGETS_TO_STATE(state: any, payload: any) {
      state.projectBudgets = payload;
    },
   
    SET_BUDGET_DATA_TO_STATE(state: any, payload: any) {
      state.budgetData = payload || [];
    },
    SET_PROJECT_ID_STATE(state: any, payload: any) {
      state.projectID = payload;
    },
    SET_BUDGET_SECTION_TO_STATE(state: any, payload: any) {
      state.mandatorySection = payload;
    },
   SET_TOTAl_PROFIT_MARGIN(state: any, payload: any) {
      state.totalProfitMargin = payload || [];
    },
    APPEND_NEW_BUDGET_SECTION_TO_STORE(state: any, payload: any) {
      state.projectBudgets?.budget_sections?.push({
        budget_items: [],
        id: payload.id,
        profit_margin: 0,
        profit_margin_percentage: 0,
        title: payload.title,
        total_cost: 0,
        total_income: 0,
      });

      state.budgetData?.push({
        budget_section_id: payload.id,
        title: payload.title,
        budget_items: [],
        profit_margin: 0,
        profit_margin_percentage: 0,
        total_cost: 0,
        total_income: 0,
      });
    },
    UPDATE_BUDGET_SECTION_TO_STORE(state: any, payload: any) {
      if (state.projectBudgets && state.projectBudgets.budget_sections && state.projectBudgets.budget_sections.id === payload.id) {
        state.projectBudgets.budget_sections = {...state.projectBudgets.budget_sections, ...payload};
    
        state.budgetData = state.budgetData.map((budgetSection: any) => { 
          if (budgetSection.budget_section_id === payload.id) {
            return {...budgetSection, ...payload};
          }
          return budgetSection;
        });
      }
    },
 
  },
  getters: {
    getAppLoading(state: any) {
      return state.appLoading;
    },
    getMandatorySection(state: any) {
      return state.mandatorySection;
    },
    
    getStructure(state: any) {
      return state.structure;
    },
  
    getAccount(state: any) {
      return state.account;
    },
    getBudgetSections(state: any) {
      //assign row index
      // Check if budget_sections exists in state.projectBudgets
      if (state.projectBudgets?.budget_sections) {
        // Clone the budget_sections array to avoid modifying the original data
        const clonedBudgetSections = [...state.projectBudgets.budget_sections];

        // Assign row index to each element in the cloned array
        clonedBudgetSections.forEach((section) => {
          section?.budget_items?.forEach(
            (item: BudgetLineItem, index: { index: number | string }) => {
              item.row_index = index;
            }
          );
        });

        return clonedBudgetSections;
      }

      return state.projectBudgets?.budget_sections;
    },
   
    isBudgetSectionsID(state: any) {
      return state.projectBudgetSetionId;
    },
    getBudgetData(state: any) {
      return state.budgetData;
    },
    getTotalProfitMargin(state: any) {
      return state.totalProfitMargin;
    }

 
  
  },
  actions: {
    getCalculationBudgets(context: ActionContext<any, any>, payload: any) {
      // context.commit("SET_APP_LOADING_TO_STATE", true);
      Api()
        .get(`${getProjectCalculationBudget}${payload}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            // context.commit("SET_APP_LOADING_TO_STATE", true);
            const responseData = response?.data?.data;
            context.commit("SET_TOTAl_PROFIT_MARGIN", responseData.bottom_table_data);
            const budgetSections = responseData.budget_sections
            const payloadBudgetData = responseData?.budget_sections?.map(
              (budgetSection: any) => {
                return {
                  budget_section_id: budgetSection.id,
                  title: budgetSection.title,
                  total_income: budgetSection.total_income,
                  total_cost: budgetSection.total_cost,
                  profit_margin: budgetSection.profit_margin,
                  profit_margin_percentage:
                    budgetSection.profit_margin_percentage,
                    budget_items: budgetSection.budget_items?.map(
                      (newItem: any) => {
                        return newItem?.id
                          ? {
                            ...newItem,
                              budget_item_id: newItem?.id,
                              structure: newItem?.structure,
                              position: newItem?.position,
                              number_of_units: newItem?.number_of_units,
                              amount_per_unit: newItem?.amount_per_unit,
                              account: newItem?.account,
                              row_index: newItem?.row_index,
                            }
                          : budgetSection.budget_items;
                      }
                    ),
                };
              }
            );
            context.commit("SET_BUDGET_DATA_TO_STATE", payloadBudgetData);
            context.commit("SET_ALL_PROJECT_BUDGETS_TO_STATE", {
              ...response.data.data,
              budget_sections: budgetSections,
            });
          }
        });
    },

    

    

   
  },
};
