export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    allCheques: [],
    addCheque: false,
    appLoading: false,
    chequeCurrency: [],
    chequeBudgetPosition: [],
    isEditCheque: false,
    isChequeEditId: null,
    chequeStartDate: null,
    chequeEndDate: null,
    chequeBalance: null,
  },
  mutations: {
    SET_CHEQUES_TO_STATE(state: any, payload: any) {
      state.allCheques = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_TOTAL_DATA_TO_STATE(state: any, payload: any) {
      state.totalData = payload;
    },
    SET_ADD_CHEQUE_TO_STATE(state: any, payload: any) {
      state.addCheque = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CHEQUE_CURRENCY_TO_STATE(state: any, payload: any) {
      state.chequeCurrency = payload;
    },
    SET_CHEQUE_BUDGET_POSITION_TO_STATE(state: any, payload: any) {
      state.chequeBudgetPosition = payload;
    },
    SET_EDIT_CHEQUE_TO_STATE(state: any, payload: any) {
      state.isEditCheque = payload;
    },
    SET_CHEQUE_EDIT_ID_TO_STATE(state: any, payload: any) {
      state.isChequeEditId = payload;
    },
    SET_CHEQUE_START_DATE_TO_STATE(state: any, payload: any) {
      state.chequeStartDate = payload;
    },
    SET_CHEQUE_END_DATE_TO_STATE(state: any, payload: any) {
      state.chequeEndDate = payload;
    },
    SET_CHEQUE_BALANCE_TO_STATE(state: any, payload: any) {
      state.chequeBalance = payload;
    },
  },
  getters: {
    isAllCheques(state: any) {
      return state.allCheques;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isTotalData(state: any) {
      return state.totalData;
    },
    isAddCheque(state: any) {
      return state.addCheque;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isChequeCurrency(state: any) {
      return state.chequeCurrency;
    },
    isChequeBudgetPosition(state: any) {
      return state.chequeBudgetPosition;
    },
    isEditCheque(state: any) {
      return state.isEditCheque;
    },
    isChequeEditId(state: any) {
      return state.isChequeEditId;
    },
    isChequeStartDate(state: any) {
      return state.chequeStartDate;
    },
    isChequeEndDate(state: any) {
      return state.chequeEndDate;
    },
    isChequeBalance(state: any) {
      return state.chequeBalance;
    },
  },
  actions: {},
};
