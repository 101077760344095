<template>
  <component :is="Layout">
  </component>
</template>

<script lang="js">
import { defineComponent } from "vue";
import BlankLayout from "@/components/shared/BlankLayout.vue";
import DashboardLayout from "@/components/shared/DashboardLayout.vue";

export default defineComponent({
  name: "App ",
  components: {
    BlankLayout,
    DashboardLayout,
  },
  data() {
    return {
      socket: null,
      listenMessage: null,
    };
  },

  computed: {
    Layout() {
      if (this.$route.meta.layout === "dashboard") return "dashboard-layout";
      if (this.$route.meta.layout === "blank") return "blank-layout";
      return null;
    },
    userKey() {
      return this.$store.getters["auth/isUserDetails"];
    },
  },
  methods: {
    connectWebSocket() {
      let socketUrl = process.env.VUE_APP_WEB_SOCKET_URL;
      this.socket = new WebSocket(`${socketUrl}${this.userKey?.user_key}/`);
      


      this.socket.addEventListener("open", () => {
        // console.log("WebSocket connected");
       
      });

      this.socket.addEventListener("message", (event) => {
        let extractedMessage = this.extraJSONData(event?.data);
        if (extractedMessage) { 
        this.listenMessage = extractedMessage;
      }

      });

      this.socket.addEventListener("error", () => {
       
      });

      this.socket.addEventListener("close", () => {
        
      });
    },

    extraJSONData (passedJSON){
      try {
        const parsedData = JSON.parse(passedJSON); 
        if (parsedData.data && typeof parsedData.data === 'string') {
          const innerData = JSON.parse(parsedData.data);
          return innerData.message;
        }
        return parsedData.data.message; 
      } catch (error) {
        // console.log("Error parsing JSON", error);
        return "Error in message format"; 
      }

    }
  },
  watch: {
    listenMessage(newValue) {
      if (newValue){
        this.$toast.open({
          message: newValue,
          type: "info",
          duration: 10000,
        });
      }
    }
  },
  mounted() {
    // this.connectWebSocket();
    
  }
});
</script>

<style>
#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-table .v-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
}

/* FONTSTYLES */
/*Header */
.H900 {
  font-weight: 500;
  font-size: 2.074rem;
  line-height: 40px;
  color: #101828;
}

.H800 {
  font-weight: 500;
  font-size: 1.728rem;
  line-height: 32px;
  color: #101828;
}

.H700 {
  font-family: inter;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.H600 {
  font-family: inter;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 24px;
  color: #101828;
}

.H500 {
  font-family: inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #101828;
}

.H400 {
  font-family: inter;
  font-weight: 500;
  font-size: 0.833rem;
  line-height: 16px;
  color: #101828;
}

.H300 {
  font-family: inter;
  font-weight: 500;
  font-size: 0.694rem;
  line-height: 12px;
  color: #101828;
}

.H200 {
  font-weight: 500;
  font-size: 0.694rem;
  line-height: 12px;
  color: #101828;
}

.H100 {
  font-weight: 500;
  font-size: 0.579rem;
  line-height: 12px;
  color: #101828;
}

/* paragraph tag class */
.P300 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.P200 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #667085;
}

.DFONT {
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 23px;
text-align: left;
list-style-type: none;

}
.HFONT {
font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 23px;
text-align: left;
list-style-type: none;

}
/*margin spacing*/
.SPC-MT-100 {
  margin-top: 8px;
}

.SPC-MT-200 {
  margin-top: 16px;
}

.SPC-MT-300 {
  margin-top: 24px;
}

.SPC-MT-400 {
  margin-top: 32px;
}

.v-application__wrap {
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  /* min-height: 0vh !important; */
  /* min-height: 0dvh !important; */
  position: relative;
}
</style>
