import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getAllAccruals,
  getAllAccrualsMirror,
  getSingleAccrual,
  costCenterRelatedData,
} from "@/services/endpoints";


export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    appLoading: false,
    allAccruals: [],
    currentAccrual: {},
    currentAccrualId: null,
    accrualsUpdate: {},
    headers: [
      {
        key: "cost_center",
        label: "Cost Center",
      },
      {
        key: "name",
        label: "Item/Name",
      },
      {
        key: "contract_id",
        label: "Contract",
      },
      {
        key: "number",
        label: "Number",
        type: "digit",
      },
      {
        key: "period",
        label: "Accounting Period",
      },
      {
        key: "invoice_number",
        label: "Invoice No.",
      },
      {
        key: "payment_date",
        label: "Payment Date",
        type: "date",
      },

      {
        key: "amount",
        label: "Total",
        type: "currency",
      },
      {
        key: "account_number",
        label: "Acct No.",
      },
      {
        key: "controller_status",
        label: "Action",
      },
    ],
    mirrorHeaders: [
      {
        key: "cost_center",
        label: "Cost Center",
      },
      {
        key: "name",
        label: "Name/Item",
      },
      {
        key: "contract_number",
        label: "Contract No.",
      },
      {
        key: "number",
        label: "Number",
        type: "digit",
      },
      {
        key: "period",
        label: "Accounting Period",
      },
      {
        key: "invoice_number",
        label: "Invoice No.",
      },
      {
        key: "accruals_previous_year",
        label: "Accrual (Previous Year)",
        type: "currency",
      },
      {
        key: "accrual_current_year",
        label: "Accrual (Current Year)",
        type: "currency",
      },
      {
        key: "consumption_current_year",
        label: "Consumption (Current Year)",
        type: "currency",
      },

      {
        key: "yield",
        label: "Yield",
        type: "currency",
      },
     
      {
        key: "remaining_current_year",
        label: "Rest For (Current Year)",
        type: "currency",
      },
    ],
    allCostCenterRelatedData: [],
    filteredAccrauls: [],
    allAccrualMirror: [],
    allAccrualsData: [],
  },
  mutations: {
    SET_ACCRUALS_TO_STATE(state: any, payload: any) {
      state.allAccruals = payload;
    },
    SET_FILTERED_ACCRUALS_TO_STATE(state: any, payload: any) {
      state.filteredAccrauls = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_ACCRUAL_TO_STATE(state: any, payload: any) {
      state.currentAccrual = payload;
    },
    SET_CURRENT_ACCRUAL_ID_TO_STATE(state: any, payload: any) {
      state.currentAccrualId = payload;
    },
    SET_COST_CENTER_RELATED_DATA(state: any, payload: any) {
      state.allCostCenterRelatedData = payload;
    },
    SET_ACCRUAL_MIRROR_TO_STATE(state: any, payload: any) {
      state.allAccrualMirror = payload;
    },
    SET_MIRROR_HEADERS_TO_STATE(state: any, payload: any) {
      state.mirrorHeaders = payload;
    },
    SET_ALL_ACCRUALS_DATA_TO_STATE(state: any, payload: any) {
      state.allAccrualsData = payload;
    },
  },
  getters: {
    allAccruals(state: any) {
      return state.allAccruals;
    },
    allAccrualsData(state: any) {
      return state.allAccrualsData;
    },
    headers(state: any) {
      return state.headers;
    },
    reserveHeaders(state: any) {
      // Create a shallow copy of the original array
      const headers = [...state.headers];
      // Insert the new item at index 1
      headers?.splice(8, 0, {
        key: "reserved_amount",
        label: "Reserve",
        type: "currency",
      });
      // Return the new array
      return headers;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    currentAccrual(state: any) {
      return state.currentAccrual;
    },
    currentAccrualId(state: any) {
      return state.currentAccrualId;
    },
    budgetItems(state: any) {
      return state.budgetItems;
    },
    accrualsUpdate(state: any) {
      return state.accrualsUpdate;
    },
    allCostCenterRelatedData(state: any) {
      return state.allCostCenterRelatedData;
    },
    getAllAccruals(state: any) {
      return state.filteredAccrauls;
    },
    allAccrualMirror(state: any) {
      return state.allAccrualMirror;
    },
    mirrorHeaders(state: any) {
      return state.mirrorHeaders;
    },
  },
  actions: {
    getAllAccruals(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getAllAccruals}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.dispatch("formatResponseData", responseData);
            context.commit("SET_ACCRUALS_TO_STATE", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    searchAndFilterAccruals(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllAccruals}?search=${payload.search || ""}&company=${
            payload.company || ""
          }&business_area=${payload.business_area || ""}&status=${
            payload.status || ""
          }&filter_by=${payload.filter_by || ""}&year=${payload.year || ""}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.dispatch("formatResponseData", responseData);
            context.commit("SET_ACCRUALS_TO_STATE", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSingleAccrual(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getSingleAccrual}${payload.transaction_type}/${context.getters.currentAccrualId}/`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_CURRENT_ACCRUAL_TO_STATE", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getCostCenterRelatedData(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${costCenterRelatedData}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_COST_CENTER_RELATED_DATA", responseData);
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    formatResponseData(context: ActionContext<any, any>, payload: any) {
      const responseData = payload;
      // console.log(responseData);
      // Step 2: Define keys to be excluded
      const excludedKeys = [
        "total_booked",
        "total",
        "provision_paid",
        "costs_booked",
        "reserves",
        "detail",
        "status",
        "costs_booked_reserves",
        "provision_posted_reserve",
        "total_reserves",
        "total_all",
        "total_current",
        "total_not_current",
      ];

      // Step 3: Filter out excluded keys
      const filteredKeys = Object.keys(responseData)?.filter(
        (key) => !excludedKeys?.includes(key)
      );

      // Step 4: Restructure data
      const restructuredData: { section_title: string; section_data: any }[] =
        filteredKeys?.reduce(
          (acc: { section_title: string; section_data: any }[], key) => {
            const modifiedData = responseData[key]?.map((accrual: any) => ({
              ...accrual,
             
              payment_date: !accrual.payment_date
                ? "-"
                : accrual.payment_date?.formattedDate(),
              period: `${
                accrual.start_date === null
                  ? ""
                  : accrual.start_date?.formattedDate()
              } - ${
                accrual.end_date === null
                  ? ""
                  : accrual.end_date.formattedDate()
              }`,
             
            }));
            // Only include sections with non-empty data
            if (modifiedData.length > 0) {
              acc.push({
                section_title: key,
                section_data: modifiedData,
              });
            }
           
            return acc;
          },
          []
        );
        
      context.commit("SET_FILTERED_ACCRUALS_TO_STATE", restructuredData);
    },
    searchAndFilterAccrualMirrors(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllAccrualsMirror}?search=${payload.search || ""}&company=${
            payload.company || ""
          }&business_area=${payload.business_area || ""}&sort_by=${payload.sort_by || ""}&year=${payload.year || ""}&tab_category=${payload.tab_category || ""}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            // console.log(response.data.data);
            context.commit("SET_ALL_ACCRUALS_DATA_TO_STATE", response.data.data);
             context.dispatch("formatResponseData", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    
  },
};
