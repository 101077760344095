import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getOneRequestedFundForApprovalUrl,
  postOneRequestedFundForApprovalUrl,
} from "@/services/endpoints";

export default {
  namespaced: true,

  state: {
    error: {},
    response: {},
    appLoading: false,
    isApprovalOverview: true,
    oneRequestedFundForApproval: {},
    requestedFundId: "",
    responseId: null,
    transferMode: [
      {
        name: "Cash",
        value: "cash",
      },
      {
        name: "Bank",
        value: "bank",
      },
    ],
    clickedAllFunds: false,
  },

  mutations: {
    SET_ERROR(state: any, data: any) {
      state.error = { ...data };
    },
    SET_RESPONSE(state: any, data: any) {
      state.response = { ...data };
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: boolean) {
      state.appLoading = payload;
    },
    SET_IS_APPROVAL_OVERVIEW(state: any, payload: boolean) {
      state.isApprovalOverview = payload;
    },

    SET_ONE_REQUESTED_FUND_FOR_APPROVAL(state: any, data: any) {
      state.oneRequestedFundForApproval = { ...data };
    },
    SET_REQUESTED_FUNDS_BODY_REQUESTER_SIGNATURE(state: any, data: any) {
      state.oneRequestedFundForApproval[`${data["who"]}_to_sign`]["signature"] =
        data["signature"];
    },
    SET_REQUESTED_FUND_ID(state: any, data: number) {
      state.requestedFundId = data;
    },
    SET_RESPONSE_ID(state: any, data: number) {
      state.responseId = data;
    },
    SET_CLICKED_ALL_FUNDS(state: any, data: boolean) {
      state.clickedAllFunds = data;
    },
  },
  getters: {
    getError(state: any) {
      return state.error;
    },
    getResponse(state: any) {
      return state.response;
    },
    getAppLoading(state: any) {
      return state.appLoading;
    },
    getIsApprovalOverview(state: any) {
      return state.isApprovalOverview;
    },
    getOneRequestedFundForApproval(state: any) {
      return state.oneRequestedFundForApproval;
    },
    getRequestedFundId(state: any) {
      return state.requestedFundId;
    },
    getResponseId(state: any) {
      return state.responseId;
    },
    isTransferMode(state: any) {
      return state.transferMode;
    },
    isClickedAllFunds(state: any) {
      return state.clickedAllFunds;
    },
  },

  actions: {
    async fetchOneRequestedForApproval(
      { commit }: ActionContext<any, any>,
      payload: { requestedFund: number }
    ) {
      try {
        commit("SET_APP_LOADING_TO_STATE", true);
        const data = await Api().get(
          `${getOneRequestedFundForApprovalUrl}${payload.requestedFund}/`
        );
        commit("SET_ONE_REQUESTED_FUND_FOR_APPROVAL", {
          ...data?.data?.data,
        });

        commit("SET_ERROR", {});
        commit("SET_APP_LOADING_TO_STATE", false);
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_ONE_REQUESTED_FUND_FOR_APPROVAL", {});
      }
    },

    async approveOneRequestedFund(
      { commit }: ActionContext<any, any>,
      payload: { requestedFund: number; request: any }
    ) {
      try {
        const data = await Api().post(
          `${postOneRequestedFundForApprovalUrl}${payload.requestedFund}/`,
          payload.request
        );

        commit("SET_RESPONSE", {
          ...data?.data,
        });
        commit("SET_REQUESTED_FUND_ID", payload.requestedFund);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
  },
};
