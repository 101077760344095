import Api from "@/services/api";
import { ActionContext } from "vuex";
import { manageBreakEvenControlling, viewBreakEvenControllingReport } from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    appLoading: false,
    allBreakEven: [],
    allBreakEvenReport: [],
    headers: [
      { key: "title", label: "Title" },
      { key: "amount", label: "Amount (EUR)", type: "currency" },
      {
        key: "number_of_employees",
        label: "Number (Employees)",
        type: "digit",
      },
      { key: "total_working_months", label: "Number (Month)", type: "digit" },
    ],
    singleBreakEven: {},
    singleBreakEvenId: null,
  },
  mutations: {
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_BREAK_EVEN_TO_STATE(state: any, payload: any) {
      state.allBreakEven = payload;
    },
    SET_BREAK_EVEN_REPORT_TO_STATE(state: any, payload: any) {
      state.allBreakEvenReport = payload;
    },

    SET_SINGLE_BREAK_EVEN_TO_STATE(state: any, payload: any) {
      state.singleBreakEven = payload;
    },
    SET_SINGLE_BREAK_EVEN_ID_TO_STATE(state: any, payload: any) {
      state.singleBreakEvenId = payload;
    },
  },
  getters: {
    getAllBreakEven(state: any) {
      return state.allBreakEven;
    },
    getAllBreakEvenReport(state: any) {
      return state.allBreakEvenReport;
    },
    header(state: any) {
      return state.headers;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    getSingleBreakEven(state: any) {
      return state.singleBreakEven;
    },
    getSingleBreakEvenId(state: any) {
      return state.singleBreakEvenId;
    },
  },
  actions: {
    getAllBreakEvens(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", payload.load_page);
      }
      Api()
        .get(
          `${manageBreakEvenControlling}?year=${
            payload.year || ""
          }&break_even_type=${payload.break_even_type || ""}`
        )
        .then((response) => {
          context.commit("SET_BREAK_EVEN_TO_STATE", response.data.data);
          context.commit("SET_APP_LOADING_TO_STATE", false);
        })
        .catch((error) => {
          if(error){
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllBreakEvensReport(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", payload.load_page);
      }
      Api()
        .get(
          `${viewBreakEvenControllingReport}?year=${
            payload.year || ""
          }&break_even_type=${payload.break_even_type || ""}`
        )
        .then((response) => {
          context.commit("SET_BREAK_EVEN_REPORT_TO_STATE", response.data.data);
          context.commit("SET_APP_LOADING_TO_STATE", false);
        })
        .catch((error) => {
          if(error){
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSingleBreakEven(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", payload.load_page);
      }
      Api()
        .get(
          `${manageBreakEvenControlling}${context.getters.getSingleBreakEvenId}/${payload.year}`
        )
        .then((response) => {
          context.commit("SET_SINGLE_BREAK_EVEN_TO_STATE", response.data.data);
          context.commit("SET_APP_LOADING_TO_STATE", false);
        })
        .catch((error) => {
          if(error){
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
