import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
    getAllFlightOverview,
    getAllFlightSettlement,
    getAllOpenFlights,
    getSelectedFlightSettlement
} from "@/services/endpoints";



export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    loading: false,
    allFightOverview: [],
    allFightSettlement: [],
    allSelectedFightSettlement: {},
    allOpenFights: [],
    currentFlightOverview: {},
    currentFlightOverviewId: null,
    selectedFlightSettelementId: null,
    headers:[],
    topHeaders:[],
    selected:[],
    selectedCompanyId: null,
    selectedInvoiceInfo: {}
  },
  mutations: {
    SET_FLIGHT_OVERVIEW_TO_STATE(state: any, payload: any) {
      state.allFightOverview = payload;
    },
    SET_FLIGHT_SETTLEMENT_TO_STATE(state: any, payload: any) {
      state.allFightSettlement = payload;
    },
    SET_OPEN_FLIGHTS_TO_STATE(state: any, payload: any) {
      state.allOpenFights = payload;
    },
    SET_SELECTED_FLIGHT_SETTLEMENT_TO_STATE(state: any, payload: any) {
      state.allSelectedFightSettlement = payload;
    },
    SET_LOADING_TO_STATE(state: any, payload: any) {
      state.loading = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
  
    SET_CURRENT_FLIGHT_OVERVIEW_TO_STATE(state: any, payload: any) {
      state.currentFlightOverview = payload;
    },
    SET_CURRENT_FLIGHT_OVERVIEW_ID_TO_STATE(state: any, payload: any) {
      state.currentFlightOverviewId = payload;
    },
    SET_HEADER_TO_STATE(state: any, payload: any) {
      state.headers = payload;
    },
    SET_TOP_HEADER_TO_STATE(state: any, payload: any) {
      state.topHeaders = payload;
    },
    SET_SELECTED_TO_STATE(state: any, payload: any) {
      state.selected = payload;
    },
    SET_SELECTED_COMPANY_ID_TO_STATE(state: any, payload: any) {
      state.selectedCompanyId = payload;
    },
    SET_SELECTED_FLIGHT_SETTLEMENT_ID_TO_STATE(state: any, payload: any) {
      state.selectedFlightSettelementId = payload;
    },
    SET_SELECTED_INVOICE_INFO_TO_STATE(state: any, payload: any) {
      state.selectedInvoiceInfo = payload;
    },


  },
  getters: {
    getFlightOverview: (state: any) => {
      return state?.allFightOverview;
    },
    getAllFlightSettlement: (state: any) => {
      return state?.allFightSettlement;
    },
    getSelectedFlightSettlement: (state: any) => {
      return state?.allSelectedFightSettlement;
    },
    getSelectedFlightSettlementId: (state: any) => {
      return state?.selectedFlightSettelementId;
    },
    getSelectedFlightSettlementData: (state: any) => {
      return state?.allSelectedFightSettlement.flight_data;
    },

    getFlightOpenInvoices: (state: any) => {
      const data = state?.allSelectedFightSettlement.open_flight_invoices || [];
      return data?.map((item: any) => { 
        return {
          text: `${item.invoice_number} - ${item.supplier} - ${item.flight_icon_company__title} - € ${item.amount.getCurrencyFormat()}`,
          value: item.id
        }
      });
    },
    getAllOpenFlights: (state: any) => {
      return state?.allOpenFights.map((item: any) => {
        return {
          ...item,
          flight_id : item.id,
          id: item.unique_id,
        } 
      })
    },
    getLoading: (state: any) => {
      return state.loading;
    },
    currentPage: (state: any) => {
      return state.currentPage;
    },
    totalItems: (state: any) => {
      return state.totalPages;
    },
    tableHeaders: (state: any) => {
      return state?.headers;
    },
    topHeaders: (state: any) => {
      return state?.topHeaders;
    },
    currentFlightOverview: (state: any) => {
      return state.currentFlightOverview;
    },
    currentFlightOverviewId: (state: any) => {
      return state.currentFlightOverviewId;
    },
    selectedCompanyId: (state: any) => {
      return state.selectedCompanyId;
    },
    getSelectedInvoiceInfo(state: any) {
      return state.selectedInvoiceInfo;
    }
  },
  actions: {
    getAllFlightOverview(context: ActionContext<any, any>, payload: any) {
    
      if (payload.load_page) {
        context.commit("SET_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllFlightOverview}?search=${payload.search || ''}&requested_from=${payload.requested_from || ''}&business_area=${payload.business_area || ''}&company=${payload.company || ''}&year=${payload.year || ''}&priority=${payload.priority || ''}&ticket_via=${payload.ticket_via || ''}&category=${payload.category || ''}&order_by=${payload.order_by || ''}`
        )
        .then((response: any) => {
          // console.log(response.data, "project reference")
          if (response.data.status === 'success') {
          
              context.commit("SET_LOADING_TO_STATE", false);
            context.commit("SET_FLIGHT_OVERVIEW_TO_STATE", response.data.data);
          }
        })

        .catch((error: any) => {
          // console.log(error.response, "hospitality package")
          if (error) {
            context.commit("SET_LOADING_TO_STATE", false);
            // this.$router.push({ name: "accessDenied" });
          }
        });
    },
    getAllFlightSettlement(context: ActionContext<any, any>, payload: any) {
    
      if (payload.load_page) {
        context.commit("SET_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllFlightSettlement}?search=${payload.search || ''}&company=${payload.company || ''}`
        )
        .then((response: any) => {
          if (response.data.status === 'success') {
              context.commit("SET_LOADING_TO_STATE", false);
            context.commit("SET_FLIGHT_SETTLEMENT_TO_STATE", response.data.data);
          }
        })

        .catch((error: any) => {
          // console.log(error.response, "hospitality package")
          if (error) {
            context.commit("SET_LOADING_TO_STATE", false);
            // this.$router.push({ name: "accessDenied" });
          }
        });
    },
    getAllOpenFlights(context: ActionContext<any, any>, payload: any) {
    
      if (payload.load_page) {
        context.commit("SET_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllOpenFlights}${payload.company_id}/`
        )
        .then((response: any) => {
        
          if (response.data.status === 'success') {
            context.commit("SET_LOADING_TO_STATE", false);
            context.commit("SET_OPEN_FLIGHTS_TO_STATE", response.data.data);
          }
        })

        .catch((error: any) => {
          // console.log(error.response, "hospitality package")
          if (error) {
            context.commit("SET_LOADING_TO_STATE", false);
            // this.$router.push({ name: "accessDenied" });
          }
        });
    },
    getSelectedFlightSettlement(context: ActionContext<any, any>, payload: any) {
    
      if (payload.load_page) {
        context.commit("SET_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getSelectedFlightSettlement}${context.getters.getSelectedFlightSettlementId}/`
        )
        .then((response: any) => {
         
          if (response.data.status === 'success') {
            context.commit("SET_LOADING_TO_STATE", false);
            context.commit("SET_SELECTED_FLIGHT_SETTLEMENT_TO_STATE", response.data?.data);
            context.commit("SET_SELECTED_COMPANY_ID_TO_STATE", response.data?.data?.company);
          }
        })

        .catch((error: any) => {
          // console.log(error.response, "hospitality package")
          if (error) {
            context.commit("SET_LOADING_TO_STATE", false);
            // this.$router.push({ name: "accessDenied" });
          }
        });
    }
  },
};
