<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.05371 3.9987H3.38704M3.38704 3.9987H14.0537M3.38704 3.9987V13.332C3.38704 13.6857 3.52752 14.0248 3.77757 14.2748C4.02762 14.5249 4.36676 14.6654 4.72038 14.6654H11.387C11.7407 14.6654 12.0798 14.5249 12.3299 14.2748C12.5799 14.0248 12.7204 13.6857 12.7204 13.332V3.9987H3.38704ZM5.38704 3.9987V2.66536C5.38704 2.31174 5.52752 1.9726 5.77757 1.72256C6.02762 1.47251 6.36676 1.33203 6.72038 1.33203H9.38704C9.74067 1.33203 10.0798 1.47251 10.3299 1.72256C10.5799 1.9726 10.7204 2.31174 10.7204 2.66536V3.9987M6.72038 7.33203V11.332M9.38704 7.33203V11.332"
      stroke="#667085"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
