<template>
  <div>
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.56592 11.7129C8.9238 12.1913 9.38039 12.5872 9.90471 12.8736C10.429 13.1601 11.0088 13.3305 11.6048 13.3731C12.2007 13.4158 12.7989 13.3298 13.3587 13.121C13.9185 12.9122 14.4269 12.5854 14.8493 12.1629L17.3493 9.66286C18.1082 8.87702 18.5282 7.82451 18.5187 6.73202C18.5092 5.63954 18.071 4.59448 17.2985 3.82195C16.526 3.04942 15.4809 2.61121 14.3884 2.60172C13.2959 2.59223 12.2434 3.0122 11.4576 3.7712L10.0243 5.19619M11.8993 10.0462C11.5414 9.56775 11.0848 9.17188 10.5605 8.88541C10.0361 8.59895 9.45633 8.4286 8.86038 8.38592C8.26443 8.34324 7.66627 8.42922 7.10647 8.63804C6.54667 8.84686 6.03833 9.17364 5.61592 9.59619L3.11592 12.0962C2.35693 12.882 1.93695 13.9345 1.94645 15.027C1.95594 16.1195 2.39414 17.1646 3.16668 17.9371C3.93921 18.7096 4.98426 19.1478 6.07675 19.1573C7.16924 19.1668 8.22175 18.7469 9.00759 17.9879L10.4326 16.5629"
        stroke="#344054"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
  font-size: 15px;
}
</style>
