import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getAllRequestedFundUrl,
  getOneRequestedFundUrl,
  getRequestedFundStatsUrl,
  postRequestedFundsUrl,
} from "@/services/endpoints";

type Status = "" | "Pending" | "Approved" | "Disapproved";
type RequestedFund = {
  budget_item: string | number | null;
  requested_amount: number | null;
  general_fund_request: boolean;
};

export default {
  namespaced: true,

  state: {
    error: {},
    response: {},
    appLoading: false,
    requestedFunds: {},
    overviewStats: {},
    tableStats: {},
    requestedFund: {},
    oneOverviewStats: {},
    oneTableStats: {},
    requestedFundStats: {},
    requestedFundsBody: {
      start_date: null,
      end_date: null,
      project_funds: [],
      requester_signature: null,
      requester_sign_date: null,
    },
    isFundTable: false,
    oneRequestedFundPeriod: {
      start_date: null,
      end_date: null,
    },
  },

  mutations: {
    SET_ERROR(state: any, data: any) {
      state.error = { ...data };
    },
    SET_RESPONSE(state: any, data: any) {
      state.response = { ...data };
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: boolean) {
      state.appLoading = payload;
    },
    SET_ALL_REQUESTED_FUNDS(state: any, data: any) {
      state.requestedFunds = { ...data };
    },
    SET_OVERVIEW_STATS(state: any, data: any) {
      state.overviewStats = { ...data };
    },
    SET_TABLE_STATS(state: any, data: any) {
      state.tableStats = { ...data };
    },
    SET_ONE_REQUESTED_FUND(state: any, data: any) {
      state.requestedFund = { ...data };
    },
    SET_ONE_OVERVIEW_STATS(state: any, data: any) {
      state.oneOverviewStats = { ...data };
    },
    SET_ONE_TABLE_STATS(state: any, data: any) {
      state.oneTableStats = { ...data };
    },

    SET_REQUESTED_FUNDS_STATS(state: any, data: any) {
      state.requestedFundStats = { ...data };
    },

    SET_REQUESTED_FUNDS_BODY_START_DATE(state: any, data: any) {
      state.requestedFundsBody.start_date = data;
    },

    SET_REQUESTED_FUNDS_BODY_END_DATE(state: any, data: any) {
      state.requestedFundsBody.end_date = data;
    },

    SET_REQUESTED_FUNDS_BODY_REQUESTER_SIGN_DATE(state: any, data: any) {
      state.requestedFundsBody.requester_sign_date = data;
    },

    SET_REQUESTED_FUNDS_BODY_PROJECT_FUNDS(state: any, data: RequestedFund) {
      const index = state.requestedFundsBody?.project_funds?.findIndex(
        (item: RequestedFund) => item?.budget_item === data?.budget_item
      );

      if (index !== -1) {
        // update existing item
        state.requestedFundsBody.project_funds[index].requested_amount =
          data?.requested_amount;
        state.requestedFundsBody.project_funds[index].general_fund_request =
          data?.general_fund_request;
      } else {
        // insert new item
        state.requestedFundsBody?.project_funds?.push(data);
      }
      state.isFundTable = data?.budget_item === null;

      if (state.isFundTable) {
        state.requestedFundStats?.request_items?.forEach((item: any) => {
          if ("requested_amount" in item) {
            item.requested_amount = 0;
          } else {
            item["requested_amount"] = 0;
          }
        });
      } else {
        // state.requestedFundStats.funds.total_requested_amount = 0;
      }
    },
    SET_REQUESTED_FUNDS_BODY_REQUESTER_SIGNATURE(state: any, data: any) {
      state.requestedFundsBody.requester_signature = data;
    },
    RE_SET_REQUESTED_FUNDS_BODY(state: any) {
      state.requestedFundsBody = {
        start_date: null,
        end_date: null,
        project_funds: [],
        requester_signature: null,
      };
    },
    CLEAN_REQUESTED_FUNDS_BODY(state: any, data: boolean) {
      state.requestedFundsBody.project_funds =
        state.requestedFundsBody?.project_funds?.filter(
          (project_fund: { general_fund_request: boolean }) =>
            project_fund?.general_fund_request !== data
        );
    },
    SET_ONE_REQUESTED_FUND_PERIOD(state: any, data: any) {
      state.oneRequestedFundPeriod = { ...data };
    },

    ASSIGN_REQUESTED_AMOUNT_TO_BUDGET_ITEM(
      state: any,
      data: { budget_item: string | number | null; requested_amount: number }
    ) {
      const index = state.requestedFundStats?.request_items?.findIndex(
        (item: any) => item?.budget_item === data?.budget_item
      );

      if (index !== -1) {
        // update existing item
        state.requestedFundStats.request_items[index].requested_amount =
          data?.requested_amount;
      }
    },

    ASSIGN_REQUESTED_AMOUNT_TO_FUND(
      state: any,
      data: { requested_amount: number }
    ) {
      state.requestedFundStats.funds.total_requested_amount =
        data?.requested_amount;
    },

    REMOVE_REQUESTED_AMOUNTS_IN_BUDGET_ITEMS(state: any) {
      state.requestedFundStats.request_items.forEach((item: any) => {
        if ("requested_amount" in item) {
          delete item.requested_amount;
        }
      });
      // state.requestedFundStats.total_requested_amount = 0;
    },

    SUM_REQUESTED_AMOUNTS_IN_BUDGET_ITEMS(state: any) {
      let total = 0;
      state.requestedFundStats?.request_items?.forEach((item: any) => {
        if ("requested_amount" in item) {
          total += item?.requested_amount;
        }
      });
      state.requestedFundStats.total_requested_amount = total;
    },

    SET_REQUESTED_AMOUNT_TOTAL_TO_FUND_TABLE_ENTERED_VALUE(
      state: any,
      data: any
    ) {
      state.requestedFundStats.total_requested_amount = data;
    },
    SET_REQUEST_FILES(state: any, data: any) {
      state.request_files = data;
    },
  },
  getters: {
    getError(state: any) {
      return state.error;
    },
    getResponse(state: any) {
      return state.response;
    },
    getAppLoading(state: any) {
      return state.appLoading;
    },
    getAllRequestedFunds(state: any) {
      return state.requestedFunds.requested_funds;
    },
    getOverviewStats(state: any) {
      return state.overviewStats;
    },
    getTableStats(state: any) {
      return state.tableStats;
    },
    getOneRequestedFund(state: any) {
      return state.requestedFund.request_items;
    },
    getOneTableStats(state: any) {
      return state.oneTableStats;
    },
    getOneOverviewStats(state: any) {
      return state.oneOverviewStats;
    },

    getRequestedFundsStats(state: any) {
      return state.requestedFundStats;
    },

    getIsFundTable(state: any) {
      return state.isFundTable;
    },
    getOneRequestedFundPeriod(state: any) {
      return state.oneRequestedFundPeriod;
    },

    getRequestedFundsTotalAmountForFundTableInput(state: any) {
      return state.requestedFundStats.total_requested_amount;
    },

    getRequestedFundsBody(state: any) {
      return state.requestedFundsBody;
    },
  },

  actions: {
    async fetchAllRequestedFunds(
      { commit }: ActionContext<any, any>,
      payload: { foreignEstablishment: number; status: Status }
    ) {
      try {
        commit("SET_APP_LOADING_TO_STATE", true);
        const data = await Api().get(
          `${getAllRequestedFundUrl}${payload.foreignEstablishment}/?status=${payload.status}`
        );
        commit("SET_ALL_REQUESTED_FUNDS", {
          ...data?.data?.data,
        });

        commit("SET_OVERVIEW_STATS", {
          ...data?.data?.data?.overview,
        });

        commit("SET_TABLE_STATS", {
          total_amount: data?.data?.data?.total_amount,
          total_reduced_amount: data?.data?.data?.total_reduced_amount,
          total_requested_amount: data?.data?.data?.total_requested_amount,
        });

        commit("SET_ERROR", {});
        commit("SET_APP_LOADING_TO_STATE", false);
      } catch ({ response }) {
        commit("SET_ERROR", response);
        commit("SET_ALL_REQUESTED_FUNDS", {});
      }
    },

    async fetchOneRequestedFund(
      { commit }: ActionContext<any, any>,
      payload: { foreignEstablishment: number }
    ) {
      try {
        const data = await Api().get(
          `${getOneRequestedFundUrl}${payload.foreignEstablishment}/`
        );

        commit("SET_ONE_REQUESTED_FUND", {
          ...data?.data?.data,
        });
        commit("SET_ONE_OVERVIEW_STATS", {
          ...data?.data?.data?.funds,
        });

        commit("SET_ONE_REQUESTED_FUND_PERIOD", {
          start_date: data?.data?.data?.start_date,
          end_date: data?.data?.data?.end_date,
        });

        commit("SET_ONE_TABLE_STATS", {
          total_planned_amount: data?.data?.data?.total_planned_amount,
          total_remaining_amount: data?.data?.data?.total_ramaining_amount,
          total_requested_amount: data?.data?.data?.total_requested_amount,
        });

        commit("SET_ERROR", {});
      } catch ({ response }) {
        commit("SET_ERROR", response);
        commit("SET_ONE_REQUESTED_FUND", {});
      }
    },

    async fetchRequestedFundStats(
      { commit }: ActionContext<any, any>,
      payload: { foreignEstablishment: number }
    ) {
      try {
        const data = await Api().get(
          `${getRequestedFundStatsUrl}${payload.foreignEstablishment}/`
        );

        commit("SET_REQUESTED_FUNDS_STATS", {
          ...data?.data?.data,
        });

        commit("SET_ERROR", {});
      } catch ({ response }) {
        commit("SET_ERROR", response);
        commit("SET_REQUESTED_FUNDS_STATS", {});
      }
    },

    async saveRequestedFund(
      { state, commit }: ActionContext<any, any>,
      payload: { foreignEstablishment: number; request_files: any }
    ) {
      try {
        Object.assign(state.requestedFundsBody, payload.request_files);
        const data = await Api().post(
          `${postRequestedFundsUrl}${payload.foreignEstablishment}/`,
          state.requestedFundsBody
        );

        commit("SET_RESPONSE", {
          ...data?.data,
        });
        commit("SET_ERROR", {});
      } catch ({ response }) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
  },
};
