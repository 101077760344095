import { ActionContext } from "vuex";
import Api from "@/services/api";
import {
  consumeCurrency,
  getCreateUpdateOutgoingInvoices,
  getTemporaryOutgoing,
  purchaseCurrency,
} from "@/services/endpoints";
export default {
  namespaced: true,
  state: {
    datevBoolean: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
    invoiceSelection: [
      {
        text: "All invoices",
        value: 1,
      },
      {
        text: "Invoices not piad",
        value: 2,
      },
    ],
    costCenters: [],
    yearsData: [],
    companiesData: [],
    appLoading: false,
    allInvoices: [],
    initailInvoices: [],
    addAllToArray: { text: "All", value: "All" },
    addedInvoice: null,
    temporaryOutgoing: [],
    rowAdded: false,
    purchase: [],
    consumption: [],
    purchaseYear: [],
    purchaseCostCenter: [],
    purchaseCurrency: [],
    purchaseCompanies: [],
  },
  mutations: {
    SET_COST_CENTERS_TO_STATE(state: any, payload: any) {
      state.costCenters = payload;
    },
    SET_YEARS_DATA_TO_STATE(state: any, payload: any) {
      state.yearsData = payload;
    },
    SET_COMPANIES_DATA_TO_STATE(state: any, payload: any) {
      state.companiesData = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_ALL_INVOICES_TO_STATE(state: any, payload: any) {
      state.allInvoices = payload;
    },
    SET_INITAIL_INVOICES_TO_STATE(state: any, payload: any) {
      state.initailInvoices = payload;
    },
    SET_ADDED_INVOICE(state: any, payload: any) {
      state.addedInvoice = payload;
    },
    SET_TEMPORARY_OUTGOING(state: any, payload: any) {
      state.temporaryOutgoing = payload;
    },
    SET_ROW_ADDED(state: any, payload: any) {
      state.rowAdded = payload;
    },
    SET_PURCHASE_TO_STATE(state: any, payload: any) {
      state.purchase = payload;
    },
    SET_CONSUMPTION_TO_STATE(state: any, payload: any) {
      state.consumption = payload;
    },
    SET_PURCHASE_YEAR(state: any, payload: any) {
      state.purchaseYear = payload;
    },
    SET_PURCHASE_COST_CENTER(state: any, payload: any) {
      state.purchaseCostCenter = payload;
    },
    SET_PURCHASE_CURRENCY(state: any, payload: any) {
      state.purchaseCurrency = payload;
    },
    SET_PURCHASE_COMPANIES(state: any, payload: any) {
      state.purchaseCompanies = payload;
    },
  },
  getters: {
    isDatevBoolean(state: any) {
      return state.datevBoolean;
    },
    isCostCenters(state: any) {
      return state.costCenters;
    },
    isYearsData(state: any) {
      return state.yearsData;
    },
    isCompaniesData(state: any) {
      return state.companiesData;
    },
    isAllInvoices(state: any) {
      return state.allInvoices;
    },
    isInitailInvoices(state: any) {
      return state.initailInvoices;
    },
    isInvoiceSelection(state: any) {
      return state.invoiceSelection;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isAddedInvoice(state: any) {
      return state.addedInvoice;
    },
    isTemporaryOutgoing(state: any) {
      return state.temporaryOutgoing;
    },
    isRowAdded(state: any) {
      return state.rowAdded;
    },
    isPurchase(state: any) {
      return state.purchase;
    },
    isConsumption(state: any) {
      return state.consumption;
    },
    isPurchaseYear(state: any) {
      return state.purchaseYear;
    },
    isPurchaseCostCenter(state: any) {
      return state.purchaseCostCenter;
    },
    isPurchaseCurrency(state: any) {
      return state.purchaseCurrency;
    },
    isPurchaseCompanies(state: any) {
      return state.purchaseCompanies;
    },
  },
  actions: {
    getAllOutgoingInvoices(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getCreateUpdateOutgoingInvoices}?search=${payload.search}&company=${payload.company}&year=${payload.year}&month=${payload.month}&filters=${payload.invoiceType}&order_by=${payload.order_by}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_INVOICES_TO_STATE", response.data.data);
            context.commit("SET_INITAIL_INVOICES_TO_STATE", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_COST_CENTERS_TO_STATE",
              response.data.cost_centers.map((center: any) => {
                return {
                  text: center.cost_center_number,
                  value: center.id,
                };
              })
            );
            context.commit(
              "SET_YEARS_DATA_TO_STATE",
              response.data.years.map((year: any) => {
                return {
                  text: year.year,
                  value: year.year,
                };
              })
            );
            const getCompanies = response.data.companies.map((company: any) => {
              return {
                text: company.title,
                value: company.id,
              };
            });
            context.commit("SET_COMPANIES_DATA_TO_STATE", [
              context.state.addAllToArray,
              ...getCompanies,
            ]);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getAllOutgoingInvoiceTemporary(
      context: ActionContext<any, any>,
      payload: any
    ) {
      // if (payload.load_page) {
      //   context.commit("SET_APP_LOADING_TO_STATE", true);
      // }
      Api()
        .get(`${getTemporaryOutgoing}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const modifiedData = response?.data?.data?.map((invoice: any) => {
              return {
                id: invoice.id,
                date: invoice.date?.formattedDate(),
                cost_center: invoice?.cost_center,
                recipient: invoice?.recipient,
                requester: invoice?.requester,
                requested_amount:
                  invoice?.requested_amount?.getCurrencyFormat(),
                planned_date: invoice?.planned_date?.formattedDate(),
                cost_center_id: invoice?.cost_center_id,
                currency: invoice?.currency,
              };
            });
            context.commit("SET_TEMPORARY_OUTGOING", modifiedData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllCurrencyPurchase(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${purchaseCurrency}?search=${payload.search}&company=${payload.company}&cost_center=${payload.cost_center}&currency=${payload.currency}&year=${payload.year}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
           
            const getYears = response.data?.years?.map((year: any) => {
              return {
                text: year,
                value: year,
              };
            });
            const getCostCenter = response?.data?.cost_centers?.map(
              (center: any) => {
                return {
                  text: `${center.cost_center__cost_center_number} - ${center.cost_center__cost_centre__project_title}`,
                  value: center.cost_center_id,
                };
              }
            );
            const getCompanies = response?.data?.companies?.map(
              (company: any) => {
                return {
                  text: company.cost_center__icon_company__title,
                  value: company.cost_center__icon_company_id,
                };
              }
            );

            const getCurrencies = response.data?.currencies?.map(
              (currency: any) => {
                return {
                  text: currency,
                  value: currency,
                };
              }
            );

            const modifiedData = response?.data?.data?.map((purchase: any) => {
              return {
                id: purchase.id,
                cost_center: purchase.cost_center,
                currency: purchase.currency,
                supplier: purchase.supplier,
                approved_amount: purchase.approved_amount,
                current_amount: purchase.current_amount,
                invoice_number: purchase.invoice_number,
                payment_date: purchase.payment_date?.formattedDate(),
                converted_amount: purchase.converted_amount,
                exchange_rate: purchase.exchange_rate?.toFixed(2),
                invoice_type: purchase.invoice_type,
                request_id: purchase.request_id,
                contract_id: purchase.contract_id,
                mission_id: purchase.mission_id,
                invoice_type_display: purchase.invoice_type_display,
                euro_amount: purchase.euro_amount?.getCurrencyFormat(),
                currency_amount: purchase.currency_amount?.getCurrencyFormat(),
                requester: purchase.requester,
              };
            });


            context.commit("SET_PURCHASE_COST_CENTER", [
              context.state.addAllToArray,
              ...getCostCenter,
            ]);
            context.commit("SET_PURCHASE_COMPANIES", [
              context.state.addAllToArray,
              ...getCompanies,
            ]);
            context.commit("SET_PURCHASE_CURRENCY", [
              context.state.addAllToArray,
              ...getCurrencies,
            ]);
            context.commit("SET_PURCHASE_YEAR", getYears);

            context.commit("SET_PURCHASE_TO_STATE", modifiedData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getAllCurrencyConsumption(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${consumeCurrency}?search=${payload.search}&company=${payload.company}&cost_center=${payload.cost_center}&currency=${payload.currency}&year=${payload.year}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const getYears = response.data.years.map((year: any) => {
              return {
                text: year,
                value: year,
              };
            });
            const getCostCenter = response?.data?.cost_centers.map(
              (center: any) => {
                return {
                  text: `${center.cost_center__cost_center_number} - ${center.cost_center__cost_centre__project_title}`,
                  value: center.cost_center_id,
                };
              }
            );
            const getCompanies = response?.data?.companies.map(
              (company: any) => {
                return {
                  text: company.cost_center__icon_company__title,
                  value: company.cost_center__icon_company_id,
                };
              }
            );

            const getCurrencies = response.data.currencies.map(
              (currency: any) => {
                return {
                  text: currency,
                  value: currency,
                };
              }
            );

            const modifiedData = response?.data?.data?.map((purchase: any) => {
              return {
                id: purchase.id,
                cost_center: purchase.cost_center,
                currency: purchase.currency,
                supplier: purchase.supplier,
                approved_amount: purchase.approved_amount,
                current_amount: purchase.current_amount,
                invoice_number: purchase.invoice_number,
                payment_date: purchase.payment_date?.formattedDate(),
                converted_amount: purchase.converted_amount,
                exchange_rate: purchase.exchange_rate.toFixed(2),
                invoice_type: purchase.invoice_type,
                request_id: purchase.request_id,
                contract_id: purchase.contract_id,
                mission_id: purchase.mission_id,
                invoice_type_display: purchase.invoice_type_display,
                euro_amount: purchase.euro_amount?.getCurrencyFormat(),
                currency_amount: purchase.currency_amount?.getCurrencyFormat(),
                requester: purchase.requester,
              };
            });

            context.commit("SET_PURCHASE_COST_CENTER", [
              context.state.addAllToArray,
              ...getCostCenter,
            ]);
            context.commit("SET_PURCHASE_COMPANIES", [
              context.state.addAllToArray,
              ...getCompanies,
            ]);
            context.commit("SET_PURCHASE_CURRENCY", [...getCurrencies]);
            context.commit("SET_PURCHASE_YEAR", getYears);

            context.commit("SET_CONSUMPTION_TO_STATE", modifiedData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
