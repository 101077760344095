export default {
  namespaced: true,
  state: {
    viewTimesheetYear: null,
    viewTimesheetMonth: new Date(
      new Date().getFullYear(),
      new Date().getMonth()
    ).toLocaleString("en-us", { month: "long" }),
    costCenterReportingData: [],
    costCenterEmployeeReportingData: [],
  },
  mutations: {
    setViewTimesheetYear: (state: any, payload: any) => {
      state.viewTimesheetYear = payload;
    },
    setViewTimesheetMonth: (state: any, payload: any) => {
      state.viewTimesheetMonth = payload;
    },
    setCostCenterReportingData: (state: any, payload: any) => {
      state.costCenterReportingData = payload;
    },
    setCostCenterEmployeeReportingData: (state: any, payload: any) => {
      state.costCenterEmployeeReportingData = payload;
    },
  },
  getters: {
    getViewTimesheetYear: (state: any) => state.viewTimesheetYear,
    getViewTimesheetMonth: (state: any) => state.viewTimesheetMonth,
    getCostCenterReportingData: (state: any) =>
      state.costCenterReportingData.slice(),
    getCostCenterEmployeeReportingData: (state: any) =>
      state.costCenterEmployeeReportingData.slice(),
  },
  actions: {
    clearCache: (context: any) => {
      context.commit("setCostCenterReportingData", []);
      context.commit("setCostCenterEmployeeReportingData", []);
    },
  },
};
