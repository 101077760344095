<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="-0.0078125" width="28" height="28" rx="14" fill="#F2F4F7" />
    <g clip-path="url(#clip0_30336_149867)">
      <path
        d="M17.334 7.98978C17.5091 7.81469 17.7169 7.6758 17.9457 7.58103C18.1745 7.48627 18.4197 7.4375 18.6673 7.4375C18.9149 7.4375 19.1601 7.48627 19.3889 7.58103C19.6177 7.6758 19.8256 7.81469 20.0007 7.98978C20.1757 8.16488 20.3146 8.37275 20.4094 8.60152C20.5042 8.8303 20.5529 9.0755 20.5529 9.32312C20.5529 9.57074 20.5042 9.81594 20.4094 10.0447C20.3146 10.2735 20.1757 10.4814 20.0007 10.6565L11.0007 19.6565L7.33398 20.6565L8.33398 16.9898L17.334 7.98978Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_30336_149867">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(6 5.99219)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
