<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="40" height="40" rx="20" fill="" />
    <rect
      x="1"
      y="1"
      width="40"
      height="40"
      rx="20"
      stroke="#D0D5DD"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="1 3"
    />
    <path
      d="M20.9997 16.332V25.6654M16.333 20.9987H25.6663"
      stroke="#98A2B3"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
