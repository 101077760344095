<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1243_10829)">
      <path
        d="M0.995117 21.5665C3.2558 27.6593 9.12049 32.0013 15.9999 32.0013C22.8794 32.0013 28.7441 27.6593 31.0047 21.5665L15.9999 20.1753L0.995117 21.5665Z"
        fill="#FFDA44"
      />
      <path
        d="M15.9999 0.0010376C9.12049 0.0010376 3.2558 4.34304 0.995117 10.4359L15.9999 11.8271L31.0047 10.4358C28.7441 4.34304 22.8794 0.0010376 15.9999 0.0010376Z"
        fill="black"
      />
      <path
        d="M0.995188 10.4344C0.352063 12.1677 0 14.0424 0 15.9995C0 17.9567 0.352063 19.8314 0.995188 21.5647H31.0049C31.648 19.8314 32 17.9567 32 15.9995C32 14.0424 31.648 12.1677 31.0048 10.4344H0.995188Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1243_10829">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
