<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8335 12.3332L10.5002 8.9999M10.5002 8.9999L7.16688 12.3332M10.5002 8.9999V16.4999M17.4919 14.3249C18.3047 13.8818 18.9467 13.1806 19.3168 12.3321C19.6868 11.4835 19.7637 10.5359 19.5354 9.63881C19.307 8.7417 18.7865 7.94616 18.0558 7.37778C17.3251 6.80939 16.4259 6.50052 15.5002 6.4999H14.4502C14.198 5.52427 13.7278 4.61852 13.0752 3.85073C12.4225 3.08295 11.6042 2.47311 10.682 2.06708C9.75967 1.66104 8.75734 1.46937 7.75031 1.50647C6.74328 1.54358 5.75777 1.80849 4.86786 2.28129C3.97795 2.7541 3.2068 3.42249 2.6124 4.23622C2.01799 5.04996 1.61579 5.98785 1.43603 6.9794C1.25627 7.97095 1.30363 8.99035 1.57456 9.96096C1.84548 10.9316 2.33291 11.8281 3.00021 12.5832"
      stroke="#344054"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped></style>
