import { ActionContext } from "vuex";
import Api from "@/services/api";
import {
  accountBalanceOverviewEndpoint,
  balancesEndpoint,
  bankAccountsEndpoint,
  currencyExchange,
  forecast,
  getAccCashBank,
  liquidity,
  liquidityOverviewEndpoint,
  loanGuarantee,
  loanGuaranteeOverview,
} from "@/services/endpoints";
import { getYesNo } from "@/services/helpers";

interface BankAccountState {
  bankAccounts: Array<any>;
  appLoading: boolean;
  banks: Array<any>;
  companies: Array<any>;
  accountType: string;
  addAccount: boolean;
  singleBankAccountId: string;
  singleBankAccount: any;
  editBankAccount: boolean;
  accountBalance: Array<any>;
  exchangeArray: Array<any>;
  exchangeYears: Array<any>;
  exchangeData: null;
  accountCashBankData: Array<any>;
  accountCashBankCompanies: Array<any>;
  loanGuaranteeData: Array<any>;
  loanGuaranteeOverviewData: Array<any>;
  accountBankOverviewArray: Array<any>;
  accountBankOverviewArrayDollar: Array<any>;
  liquidityOverviewData: Array<any>;
  liquidityRequirementData: Array<any>;
  projectManagers: Array<any>;
  controllers: Array<any>;
  liquidityForecast: Array<any>;
}

interface GetAllBankAccountsPayload {
  company: string;
  currency: string;
  account_type: string;
  bank: string;
  search: string;
  load_page: boolean;
}

interface singleBankAccountPayload {
  id?: string;
  load_page: boolean;
}

interface accountCashBankPayload {
  load_page: boolean;
  search: string;
  company: string;
}

interface loanGuaranteeOverviewPayload {
  load_page: boolean;
  year: string;
  month: string;
  day: string;
}
interface accountBankBalancePayload {
  load_page: boolean;
  date: string;
  company: string;
  currency: string;
}
interface liquidityOverviewPayload {
  load_page: boolean;
  company_id: string;
  year: string;
  month: string;
}
interface liquidityRequirementPayload {
  load_page: boolean;
  company_id: string;
  business_area: string;
  project_manager: string;
  controller: string;
  tab: string;
  year: string;
}
interface liquidityForecastPayload {
  load_page: boolean;
  companies: Array<any>;
  business_area: Array<any>;
}

export default {
  namespaced: true,
  state: (): BankAccountState => ({
    bankAccounts: [],
    appLoading: false,
    banks: [],
    companies: [],
    accountType: "",
    addAccount: false,
    singleBankAccountId: "",
    singleBankAccount: null,
    editBankAccount: false,
    accountBalance: [],
    exchangeArray: [],
    exchangeYears: [],
    exchangeData: null,
    accountCashBankData: [],
    accountCashBankCompanies: [],
    loanGuaranteeData: [],
    loanGuaranteeOverviewData: [],
    accountBankOverviewArray: [],
    accountBankOverviewArrayDollar: [],
    liquidityOverviewData: [],
    liquidityRequirementData: [],
    projectManagers: [],
    controllers: [],
    liquidityForecast: [],
  }),
  mutations: {
    SET_ALL_BANK_ACCOUNT_TO_STATE: (state: BankAccountState, payload: any) => {
      state.bankAccounts = payload;
    },
    SET_APP_LOADING_TO_STATE(state: BankAccountState, payload: boolean) {
      state.appLoading = payload;
    },
    SET_BANKS_TO_STATE(state: BankAccountState, payload: any) {
      state.banks = payload;
    },
    SET_COMPANIES_TO_STATE(state: BankAccountState, payload: any) {
      state.companies = payload;
    },
    SET_ACCOUNT_TYPE_TO_STATE(state: BankAccountState, payload: string) {
      state.accountType = payload;
    },
    SET_ADD_ACCOUNT_TO_STATE(state: BankAccountState, payload: boolean) {
      state.addAccount = payload;
    },
    SET_SINGLE_BANK_ACCOUNT_ID_TO_STATE(
      state: BankAccountState,
      payload: string
    ) {
      state.singleBankAccountId = payload;
    },
    SET_SINGLE_BANK_ACCOUNT_TO_STATE(state: BankAccountState, payload: any) {
      state.singleBankAccount = payload;
    },
    SET_EDIT_BANK_ACCOUNT_TO_STATE(state: BankAccountState, payload: boolean) {
      state.editBankAccount = payload;
    },
    SET_ACCOUNT_BALANCE_TO_STATE(state: BankAccountState, payload: any) {
      state.accountBalance = payload;
    },
    SET_EXCHANGE_TO_STATE(state: BankAccountState, payload: any) {
      state.exchangeArray = payload;
    },
    SET_EXCHANGE_DATA_TO_STATE(state: BankAccountState, payload: any) {
      state.exchangeData = payload;
    },
    SET_EXCHANGE_YEARS_TO_STATE(state: BankAccountState, payload: any) {
      state.exchangeYears = payload;
    },
    SET_ACCOUNT_CASH_BANK_TO_STATE(state: BankAccountState, payload: any) {
      state.accountCashBankData = payload;
    },
    SET_ACCOUNT_CASH_BANK_COMPANIES(state: BankAccountState, payload: any) {
      state.accountCashBankCompanies = payload;
    },
    SET_LOAN_GUARANTEE_DATA(state: BankAccountState, payload: any) {
      state.loanGuaranteeData = payload;
    },
    SET_LOAN_GUARANTEE_OVERVIEW_DATA(state: BankAccountState, payload: any) {
      state.loanGuaranteeOverviewData = payload;
    },
    SET_ACCOUNT_BANK_OVERVIEW_ARRAY(state: BankAccountState, payload: any) {
      state.accountBankOverviewArray = payload;
    },
    SET_ACCOUNT_BANK_OVERVIEW_ARRAY_DOLLAR(
      state: BankAccountState,
      payload: any
    ) {
      state.accountBankOverviewArrayDollar = payload;
    },
    SET_ALL_LIQUIDITY_DATA_TO_STATE(state: BankAccountState, payload: any) {
      state.liquidityOverviewData = payload;
    },
    SET_LIQUIDITY_REQUIREMENT_DATA(state: BankAccountState, payload: any) {
      state.liquidityRequirementData = payload;
    },
    SET_LIQUIDITY_FORECAST(state: BankAccountState, payload: any) {
      state.liquidityForecast = payload;
    },
  },
  getters: {
    bankAccounts(state: BankAccountState) {
      return state.bankAccounts;
    },
    appLoading(state: BankAccountState) {
      return state.appLoading;
    },
    banks(state: BankAccountState) {
      return state.banks;
    },
    companies(state: BankAccountState) {
      return state.companies;
    },
    accountType(state: BankAccountState) {
      return state.accountType;
    },
    addAccount(state: BankAccountState) {
      return state.addAccount;
    },
    singleBankAccountId(state: BankAccountState) {
      return state.singleBankAccountId;
    },
    singleBankAccount(state: BankAccountState) {
      return state.singleBankAccount;
    },
    editBankAccount(state: BankAccountState) {
      return state.editBankAccount;
    },
    accountBalance(state: BankAccountState) {
      return state.accountBalance;
    },
    exchangeArray(state: BankAccountState) {
      return state.exchangeArray;
    },
    exchangeData(state: BankAccountState) {
      return state.exchangeData;
    },
    exchangeYears(state: BankAccountState) {
      return state.exchangeYears;
    },
    accountCashBankData(state: BankAccountState) {
      return state.accountCashBankData;
    },
    accountCashBankCompanies(state: BankAccountState) {
      return state.accountCashBankCompanies;
    },
    loanGuaranteeData(state: BankAccountState) {
      return state.loanGuaranteeData;
    },
    loanGuaranteeOverviewData(state: BankAccountState) {
      return state.loanGuaranteeOverviewData;
    },
    accountBankOverviewArray(state: BankAccountState) {
      return state.accountBankOverviewArray;
    },
    accountBankOverviewArrayDollar(state: BankAccountState) {
      return state.accountBankOverviewArrayDollar;
    },
    liquidityOverviewData(state: BankAccountState) {
      return state.liquidityOverviewData;
    },
    liquidityRequirementData(state: BankAccountState) {
      return state.liquidityRequirementData;
    },
    liquidityForecast(state: BankAccountState) {
      return state.liquidityForecast;
    },
  },
  actions: {
    async getAllBankAccounts(
      context: ActionContext<BankAccountState, any>,
      payload: GetAllBankAccountsPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }

      try {
        const response = await Api().get(
          `${bankAccountsEndpoint}?company=${payload.company}&currency=${payload.currency}&account_type=${payload.account_type}&bank=${payload.bank}&search=${payload.search}`
        );

        if (response.data.status === "success") {
          context.commit(
            "SET_BANKS_TO_STATE",
            response?.data?.data?.banks.map((item: any) => {
              return {
                text: item.bank_name,
                value: item.id,
              };
            })
          );
          context.commit(
            "SET_COMPANIES_TO_STATE",
            response?.data?.data?.companies.map((item: any) => {
              return {
                text: item.title,
                value: item.id,
              };
            })
          );
          const modifiedResponse = response?.data?.data?.bank_accounts?.map(
            (item: any) => {
              return {
                id: item.id,
                bank: item.bank,
                company: item.company,
                account_number: item.account_number,
                account_name: item.account_name,
                account_type: item.account_type,
                currency: item.currency,
                interest_plus: item.interest_plus,
                interest_minus: item.interest_minus,
                pledge: getYesNo(item.pledge),
                compensation_contract: getYesNo(item.compensation_contract),
                signature: item.signature,
                balance: item.balance?.getCurrencyFormat(),
              };
            }
          );
          context.commit("SET_ALL_BANK_ACCOUNT_TO_STATE", modifiedResponse);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      } finally {
        if (payload.load_page) {
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      }
    },
    async singleBankAccountPoint(
      context: ActionContext<BankAccountState, any>,
      payload: singleBankAccountPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(
          `${bankAccountsEndpoint}${payload.id}`
        );
        if (response.data.status === "success") {
          context.commit(
            "SET_SINGLE_BANK_ACCOUNT_TO_STATE",
            response.data.data
          );
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      } finally {
        if (payload.load_page) {
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      }
    },
    async bankBalance(
      context: ActionContext<BankAccountState, any>,
      payload: singleBankAccountPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(`${balancesEndpoint}${payload.id}`);
        if (response.data.status === "success") {
          context.commit("SET_ACCOUNT_BALANCE_TO_STATE", response.data.data);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      } finally {
        if (payload.load_page) {
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      }
    },
    async getCurrencyExchange(
      context: ActionContext<BankAccountState, any>,
      payload: singleBankAccountPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(`${currencyExchange}`);
        if (response.data.status === "success") {
          context.commit(
            "SET_EXCHANGE_TO_STATE",
            response.data.data.currency_exchanges
          );
          context.commit(
            "SET_EXCHANGE_DATA_TO_STATE",
            response.data.data.recent_exchange
          );
          context.commit(
            "SET_EXCHANGE_YEARS_TO_STATE",
            response.data.data.available_years
          );
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async getAccountCashBank(
      context: ActionContext<BankAccountState, any>,
      payload: accountCashBankPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(
          `${getAccCashBank}?search=${payload.search}&company=${payload.company}`
        );
        if (response.data.status === "success") {
          const modifiedResponse = response.data?.data?.data?.map(
            (item: any) => {
              return {
                id: item?.id,
                cost_center: item?.cost_center,
                project_office: item?.project_office,
                project_name: item?.project_name,
                cash_account: item?.cash_account,
                bank_account: item?.bank_account,
                subsidiary_bank_account: item?.subsidiary_bank_account,
                account_pjm: item?.account_pjm,
                icon_company_account: item?.icon_company_account,
              };
            }
          );
          const modifiedCompanies = response.data?.data?.companies?.map(
            (company: any) => {
              return {
                text: company.title,
                value: company.id,
              };
            }
          );
          context.commit("SET_APP_LOADING_TO_STATE", false);
          context.commit("SET_ACCOUNT_CASH_BANK_TO_STATE", modifiedResponse);
          context.commit("SET_ACCOUNT_CASH_BANK_COMPANIES", modifiedCompanies);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async getLoanGuarantee(
      context: ActionContext<BankAccountState, any>,
      payload: singleBankAccountPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(`${loanGuarantee}`);
        if (response.data.status === "success") {
          context.commit("SET_LOAN_GUARANTEE_DATA", response?.data?.data);
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async getLoanGuaranteeOverview(
      context: ActionContext<BankAccountState, any>,
      payload: loanGuaranteeOverviewPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(
          `${loanGuaranteeOverview}?year=${payload.year}&month${payload.month}&day${payload.day}`
        );
        if (response.data.status === "success") {
          context.commit(
            "SET_LOAN_GUARANTEE_OVERVIEW_DATA",
            response.data.data
          );
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async getAccountBankBalanceEuro(
      context: ActionContext<BankAccountState, any>,
      payload: accountBankBalancePayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(
          `${accountBalanceOverviewEndpoint}?date=${payload.date}&company=${payload.company}&currency=${payload.currency}`
        );
        if (response.data.status === "success") {
          context.commit("SET_ACCOUNT_BANK_OVERVIEW_ARRAY", response.data.data);
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async getAccountBankBalanceDollar(
      context: ActionContext<BankAccountState, any>,
      payload: accountBankBalancePayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(
          `${accountBalanceOverviewEndpoint}?date=${payload.date}&company=${payload.company}&currency=${payload.currency}`
        );
        if (response.data.status === "success") {
          context.commit(
            "SET_ACCOUNT_BANK_OVERVIEW_ARRAY_DOLLAR",
            response.data.data
          );
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async getLiquidityOverviewList(
      context: ActionContext<BankAccountState, any>,
      payload: liquidityOverviewPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }

      try {
        const response = await Api().get(
          `${liquidityOverviewEndpoint}?year=${payload.year}&month=${payload.month}&company_id=${payload.company_id}`
        );
        if (response.data.status === "success") {
          context.commit(
            "SET_ALL_LIQUIDITY_DATA_TO_STATE",
            response.data.data.data
          );
          context.commit(
            "SET_COMPANIES_TO_STATE",
            response?.data?.data?.companies.map((item: any) => {
              return {
                text: item.title,
                value: item.id,
              };
            })
          );
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async getLiquidityRequirement(
      context: ActionContext<BankAccountState, any>,
      payload: liquidityRequirementPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      try {
        const response = await Api().get(
          `${liquidity}?company=${payload.company_id}&business_area=${payload.business_area}&project_manager=${payload.project_manager}&controller=${payload.controller}&tab=${payload.tab}&year=${payload.year}`
        );
        if (response.data.status === "success") {
          context.commit("SET_LIQUIDITY_REQUIREMENT_DATA", response.data?.data);
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
    async getLiquidityForecast(
      context: ActionContext<BankAccountState, any>,
      payload: liquidityForecastPayload
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }

      try {
        const response = await Api().post(`${forecast}`, payload);
        if (response.data.status === "success") {
          context.commit("SET_LIQUIDITY_FORECAST", response.data?.data);
          context.commit("SET_APP_LOADING_TO_STATE", false);
        }
      } catch (error) {
        context.commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
  },
};
