<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_10842_159772)">
      <path
        d="M8.00016 10.6693V8.0026M8.00016 5.33594H8.00683M14.6668 8.0026C14.6668 11.6845 11.6821 14.6693 8.00016 14.6693C4.31826 14.6693 1.3335 11.6845 1.3335 8.0026C1.3335 4.32071 4.31826 1.33594 8.00016 1.33594C11.6821 1.33594 14.6668 4.32071 14.6668 8.0026Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10842_159772">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
  font-size: 15px;
}
</style>
