import axios from "axios";
import * as Cookies from "js-cookie";
import config from "./config";
import store from "@/store";
import { refreshTokenEndpoint } from "./endpoints";

const api = axios.create({
  baseURL: config.apiBaseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

// Function to refresh the access token
const refreshAccessToken = async () => {
  const refreshToken = Cookies.get("refreshToken");
  // Make a request to the server to refresh the access token using the refresh token
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}${refreshTokenEndpoint}`,
      {
        refresh: refreshToken,
      }
    );

    const { access } = response.data.data;

    // Update the access token in the Axios instance headers
    api.defaults.headers.common["Authorization"] = `Bearer ${access}`;

    // Update the access token in the cookie
    Cookies.set("authToken", access);
    store?.commit("auth/SET_AUTH_TOKEN", { token: access });
    return Promise.resolve();
  } catch (error) {
    logoutAndRedirectToLogin();
    return Promise.reject(error);
  }
};

// Function to handle logout and redirect to the login page
export const logoutAndRedirectToLogin = () => {
  // Clear the authentication-related information
  Cookies.remove("authToken");
  Cookies.remove("refreshToken");
  store?.commit("auth/CLEAR_CURRENT_USER");

  // Redirect to the login page
  window.location.assign("/");
};

// Interceptor to handle token refreshing when access token expires
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Check if the response status is 401 (Unauthorized) and the original request was not already retried
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // Refresh the access token
        await refreshAccessToken();

        // Retry the original request with the updated access token
        originalRequest.headers["Authorization"] = `Bearer ${Cookies.get(
          "authToken"
        )}`;

        return api(originalRequest);
      } catch (error) {
        // Handle refresh token failure or other errors
        return Promise.reject(error);
      }
    }

    // For other errors, return the error
    return Promise.reject(error);
  }
);

// Function to get the access token from the cookie
const getAccessToken = () => {
  return Cookies.get("authToken");
};

// Interceptor to handle unauthorized responses and add authorization header
api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default () => api;

// export default () => {
//   const authToken = Cookies.get("authToken");
//   return axios.create({
//     baseURL: config.apiBaseUrl,
//     withCredentials: false,
//     headers: {
//       // 'Access-Control-Allow-Credentials':true,
//       Accept: "application/json",
//       "Content-Type": "multipart/form-data",
//       Authorization: authToken ? `Bearer ${authToken}` : null,
//     },
//   });
// };
