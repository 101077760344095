<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "SkypeIcon"
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <ellipse cx="7.71463" cy="7.32149" rx="4.85721" ry="4.57149" fill="#184AA9"/>
    <circle cx="14.2849" cy="14.1784" r="4.57149" fill="#2173F0"/>
    <ellipse cx="10.8584" cy="10.75" rx="7.42867" ry="7.42867" fill="url(#paint0_linear_22207_311499)"/>
    <path d="M10.8984 6.75C12.1061 6.75 13.7163 7.32144 13.3138 8.08335C12.7636 9.12473 11.7035 8.08335 10.8984 8.08335C10.0933 8.08335 9.69076 8.46431 9.69076 8.84527C9.69076 9.22622 9.89204 9.60718 10.8984 9.98814C11.9048 10.3691 13.7163 10.9405 13.7163 12.4644C13.7163 13.9882 12.3074 14.7501 10.4959 14.7501C8.87312 14.7501 7.67799 13.9882 8.08054 13.2263C8.54348 12.3501 9.69076 13.4168 10.4959 13.4168C11.301 13.4168 12.1061 13.2263 12.1061 12.4644C12.1061 11.7024 10.8984 11.512 10.0933 11.131C9.0869 10.6548 8.08053 10.1786 8.08053 8.84527C8.08053 7.51192 9.69076 6.75 10.8984 6.75Z" fill="white"/>
    <defs>
      <linearGradient id="paint0_linear_22207_311499" x1="5.0805" y1="6.21022" x2="16.2235" y2="15.4961" gradientUnits="userSpaceOnUse">
        <stop stop-color="#297AC0"/>
        <stop offset="1" stop-color="#48B0F9"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped>

</style>