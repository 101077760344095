import i18n from "../../i18n";

export default {
  namespaced: true,

  state: {
    locale: i18n.global.locale.value,
  },

  getters: {
    getLocale(state: any) {
      return state.locale;
    },
  },

  mutations: {
    SET_LOCALE(state: { locale: any }, locale: any) {
      state.locale = locale;
    },
  },

  actions: {
    setLocale({ commit }: any, locale: any) {
      commit("SET_LOCALE", locale);
      i18n.global.locale.value = locale;
    },
  },
};
