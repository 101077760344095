import { ActionContext } from "vuex";
import Api from "@/services/api";
import { formatDate } from "@/services/helpers";
import {
  cashOutFlow,
  getProjectInterimPayments,
  getSingleInterimPayment,
  interimBasicVersion,
  interimReportCostCenter,
  singleCashOutFlow,
} from "@/services/endpoints";
export default {
  namespaced: true,
  state: {
    headers: [
      "Cost Center",
      "Project Title",
      "Countries",
      "Project Manager",
      "Controller",
      "Start Date",
      "End Date",
    ],
    db1: [
      {
        text: "Plan",
        value: "Plan",
      },
      {
        text: "Actual",
        value: "Actual",
      },
    ],
    status: [
      {
        text: "Sent",
        value: "Sent",
      },
      {
        text: "Approved",
        value: "Approved",
      },
    ],
    appLoading: false,
    allInterimPayment: [],
    selectedItem: [],
    currentPage: 1,
    totalPages: null,
    totalData: null,
    initialInterimPayment: [],
    projectTitle: "",
    auditable: null,
    invoiceNumbers: [],
    total_planned_amount: "",
    total_advance_payment_amount: "",
    total_payable_amount: "",
    total_received_amount: "",
    total_difference_amount: "",
    cashOutFlowArray: [],
    cashOutHeaders: null,
    flowModalTable: [],
    intialCashOutFlow: [],
    modalTotals: null,
    cashOutTotals: null,
    allBasicVersion: [],
    total_initial_planned_amount: "",
    actual_total_planned_amount: "",
    total_initial_advance_payment_amount: "",
    actual_total_advance_payment_amount: "",
    total_net_amount: "", 
    reportCostCenter: [],
  },
  mutations: {
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_ALL_INTERIM_PAYMENT_TO_STATE(state: any, payload: any) {
      state.allInterimPayment = payload;
    },
    SET_INITIAL_INTERIM_PAYMENT_TO_STATE(state: any, payload: any) {
      state.initialInterimPayment = payload;
    },

    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_SELECTED_ITEM_TO_STATE(state: any, payload: any) {
      state.selectedItem = payload;
    },
    SET_PROJECT_TITLE_TO_STATE(state: any, payload: any) {
      state.projectTitle = payload;
    },
    SET_AUDITABLE_TO_STATE(state: any, payload: any) {
      state.auditable = payload;
    },
    SET_INVOICE_NUMBERS_TO_STATE(state: any, payload: any) {
      state.invoiceNumbers = payload;
    },
    SET_TOTAL_PLANNED_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_planned_amount = payload;
    },
    SET_TOTAL_ADVANCE_PAYMENT_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_advance_payment_amount = payload;
    },
    SET_TOTAL_PAYABLE_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_payable_amount = payload;
    },
    SET_TOTAL_RECEIVED_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_received_amount = payload;
    },
    SET_TOTAL_DIFFERENCE_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_difference_amount = payload;
    },
    SET_CASH_OUT_FLOW_TO_STATE(state: any, payload: any) {
      state.cashOutFlowArray = payload;
    },
    SET_CASH_OUT_HEADERS_TO_STATE(state: any, payload: any) {
      state.cashOutHeaders = payload;
    },
    SET_FLOW_MODAL_TABLE_TO_STATE(state: any, payload: any) {
      state.flowModalTable = payload;
    },
    SET_INITIAL_FLOW_MODAL_TABLE_TO_STATE(state: any, payload: any) {
      state.intialCashOutFlow = payload;
    },
    SET_MODAL_TOTALS_TO_STATE(state: any, payload: any) {
      state.modalTotals = payload;
    },
    SET_CASH_OUT_TOTALS_TO_STATE(state: any, payload: any) {
      state.cashOutTotals = payload;
    },
    SET_ALL_BASIC_VERSION_TO_STATE(state: any, payload: any) {
      state.allBasicVersion = payload;
    },
    SET_TOTAL_INITIAL_PLANNED_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_initial_planned_amount = payload;
    },
    SET_ACTUAL_TOTAL_PLANNED_AMOUNT_TO_STATE(state: any, payload: any) {
      state.actual_total_planned_amount = payload;
    },
    SET_TOTAL_INITIAL_ADVANCE_PAYMENT_AMOUNT_TO_STATE(
      state: any,
      payload: any
    ) {
      state.total_initial_advance_payment_amount = payload;
    },
    SET_ACTUAL_TOTAL_ADVANCE_PAYMENT_AMOUNT_TO_STATE(state: any, payload: any) {
      state.actual_total_advance_payment_amount = payload;
    },
    SET_REPORT_COST_CENTER_TO_STATE(state: any, payload: any) {
      state.reportCostCenter = payload;
    },
    SET_TOTAL_NET_AMOUNT_TO_STATE(state: any, payload: any) {
      state.total_net_amount = payload;
    }
  },
  getters: {
    isHeaders(state: any) {
      return state.headers;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isAllInterimPayment(state: any) {
      return state.allInterimPayment;
    },
    isInitialInterimPayment(state: any) {
      return state.initialInterimPayment;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    totalPages(state: any) {
      return state.totalPages;
    },
    totalData(state: any) {
      return state.totalData;
    },
    isSelectedItem(state: any) {
      return state.selectedItem;
    },
    isProjectTitle(state: any) {
      return state.projectTitle;
    },
    isAuditable(state: any) {
      return state.auditable;
    },
    isDb1(state: any) {
      return state.db1;
    },
    isStatus(state: any) {
      return state.status;
    },
    isInvoiceNumbers(state: any) {
      return state.invoiceNumbers;
    },
    isTotalPlannedAmount(state: any) {
      return state.total_planned_amount;
    },
    isTotalAdvancePaymentAmount(state: any) {
      return state.total_advance_payment_amount;
    },
    isTotalPayableAmount(state: any) {
      return state.total_payable_amount;
    },
    isTotalNetAmount(state: any) {
      return state.total_net_amount;
    },
    isTotalReceivedAmount(state: any) {
      return state.total_received_amount;
    },
    isTotalDifferenceAmount(state: any) {
      return state.total_difference_amount;
    },
    isCashOutFlowArray(state: any) {
      return state.cashOutFlowArray;
    },
    isCashOutHeaders(state: any) {
      return state.cashOutHeaders;
    },
    isFlowModalTable(state: any) {
      return state.flowModalTable;
    },
    isInitialFlowModalTable(state: any) {
      return state.intialCashOutFlow;
    },
    isModalTotals(state: any) {
      return state.modalTotals;
    },
    isCashOutTotals(state: any) {
      return state.cashOutTotals;
    },
    isAllBasicVersion(state: any) {
      return state.allBasicVersion;
    },
    isTotalInitialPlannedAmount(state: any) {
      return state.total_initial_planned_amount;
    },
    isActualTotalPlannedAmount(state: any) {
      return state.actual_total_planned_amount;
    },
    isTotalInitialAdvancePaymentAmount(state: any) {
      return state.total_initial_advance_payment_amount;
    },
    isActualTotalAdvancePaymentAmount(state: any) {
      return state.actual_total_advance_payment_amount;
    },
    isReportCostCenter(state: any) {
      return state.reportCostCenter;
    },
  },
  actions: {
    allProjectInterimPayments(context: ActionContext<any, any>, payload: any) {
      const lang = context.rootGetters["translation/getLocale"];
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getProjectInterimPayments}?search=${payload.search}&country=${payload.country}&page=${payload.page_number}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const modifiedData = response.data.data.map((item: any) => {
              return {
                id: item.id,
                "cost center": item.cost_centre,
                "project title": item.project_title,
                countries: item.countries,
                "project manager": item.project_manager
                  ? item.project_manager.first_name +
                    " " +
                    item.project_manager.last_name
                  : "-",
                controller: item.offer_controller,
                "start date": formatDate(item.project_start_date, lang),
                "end date": formatDate(item.project_end_date, lang),
              };
            });
            context.commit("SET_ALL_INTERIM_PAYMENT_TO_STATE", modifiedData);

            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );

            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSingleInterimPayment(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getSingleInterimPayment}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const modifiedInvoiceNumber = response.data?.invoices?.map(
              (item: any) => {
                const {
                  invoice_number: text,
                  id: value,
                  date: date,
                  requested_amount: requested_amount,
                  received_amount: received_amount,
                  payment_date: payment_date,
                  difference_amount: difference_amount,
                } = item;
                return {
                  text,
                  value,
                  date,
                  requested_amount,
                  received_amount,
                  payment_date,
                  difference_amount,
                };
              }
            );
            context.commit(
              "SET_ALL_INTERIM_PAYMENT_TO_STATE",
              response.data?.data
            );
            context.commit(
              "SET_INITIAL_INTERIM_PAYMENT_TO_STATE",
              response.data?.data
            );
            context.commit(
              "SET_PROJECT_TITLE_TO_STATE",
              response.data?.project_title
            );
            context.commit(
              "SET_AUDITABLE_TO_STATE",
              response.data?.is_auditable
            );
            context.commit(
              "SET_TOTAL_PLANNED_AMOUNT_TO_STATE",
              response.data?.total_planned_amount
            );
            context.commit(
              "SET_TOTAL_ADVANCE_PAYMENT_AMOUNT_TO_STATE",
              response.data?.total_advance_payment_amount
            );
            context.commit(
              "SET_TOTAL_PAYABLE_AMOUNT_TO_STATE",
              response.data?.total_payable_amount
            );
            context.commit(
              "SET_TOTAL_RECEIVED_AMOUNT_TO_STATE",
              response.data?.total_received_amount
            );

            context.commit(
              "SET_TOTAL_DIFFERENCE_AMOUNT_TO_STATE",
              response.data?.total_difference_amount
            );
            context.commit(
              "SET_TOTAL_NET_AMOUNT_TO_STATE",
              response.data?.total_net_amount
            );

            context.commit(
              "SET_INVOICE_NUMBERS_TO_STATE",
              modifiedInvoiceNumber
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          } else {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getCashOutFlow(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${cashOutFlow}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_CASH_OUT_FLOW_TO_STATE",
              response.data?.data?.data
            );
            context.commit(
              "SET_CASH_OUT_HEADERS_TO_STATE",
              response.data?.data?.interim_payments
            );
            context.commit(
              "SET_CASH_OUT_TOTALS_TO_STATE",
              response.data?.data?.totals
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          } else {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSingleInterimPaymentForFlow(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${singleCashOutFlow}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_FLOW_MODAL_TABLE_TO_STATE", response.data.data);
            context.commit(
              "SET_INITIAL_FLOW_MODAL_TABLE_TO_STATE",
              response.data.data
            );
            context.commit("SET_MODAL_TOTALS_TO_STATE", response.data.totals);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          } else {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllBasicVersion(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${interimBasicVersion}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_ALL_BASIC_VERSION_TO_STATE",
              response.data.data
            );
            context.commit(
              "SET_TOTAL_INITIAL_PLANNED_AMOUNT_TO_STATE",
              response.data.total_initial_planned_amount
            );
            context.commit(
              "SET_ACTUAL_TOTAL_PLANNED_AMOUNT_TO_STATE",
              response.data.total_planned_amount
            );
            context.commit(
              "SET_TOTAL_INITIAL_ADVANCE_PAYMENT_AMOUNT_TO_STATE",
              response.data.total_initial_advance_payment_amount
            );
            context.commit(
              "SET_ACTUAL_TOTAL_ADVANCE_PAYMENT_AMOUNT_TO_STATE",
              response.data.total_advance_payment_amount
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }else {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getReportCenter(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${interimReportCostCenter}`)
        .then((response: any) => {
          if (response.data.status === "success") {
           const modifiedData = response.data.data.map((item: any) => {
            return {
              text: `${item.cost_center_number} - ${item.project_title}`,
              value: item.project_id,
            }
           })
            context.commit("SET_REPORT_COST_CENTER_TO_STATE", modifiedData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
