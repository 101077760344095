<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UnlockedIcon",
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.83333 9.16631V5.83297C5.83229 4.79968 6.21523 3.80286 6.90781 3.03603C7.60039 2.2692 8.55319 1.78706 9.58125 1.68321C10.6093 1.57937 11.6393 1.86123 12.4712 2.47407C13.3032 3.08691 13.8777 3.98701 14.0833 4.99964M4.16667 9.16631H15.8333C16.7538 9.16631 17.5 9.9125 17.5 10.833V16.6663C17.5 17.5868 16.7538 18.333 15.8333 18.333H4.16667C3.24619 18.333 2.5 17.5868 2.5 16.6663V10.833C2.5 9.9125 3.24619 9.16631 4.16667 9.16631Z"
      stroke="#344054"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped></style>
