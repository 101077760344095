<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12762_74566)">
      <path
        d="M13.3335 13.4172L10.0002 10.0839M10.0002 10.0839L6.66688 13.4172M10.0002 10.0839V17.5839M16.9919 15.4089C17.8047 14.9658 18.4467 14.2646 18.8168 13.4161C19.1868 12.5675 19.2637 11.6199 19.0354 10.7228C18.807 9.82568 18.2865 9.03015 17.5558 8.46176C16.8251 7.89337 15.9259 7.5845 15.0002 7.58389H13.9502C13.698 6.60826 13.2278 5.7025 12.5752 4.93472C11.9225 4.16693 11.1042 3.5571 10.182 3.15106C9.25967 2.74503 8.25734 2.55336 7.25031 2.59046C6.24328 2.62756 5.25777 2.89247 4.36786 3.36528C3.47795 3.83808 2.7068 4.50648 2.1124 5.32021C1.51799 6.13394 1.11579 7.07184 0.936028 8.06338C0.756269 9.05493 0.803632 10.0743 1.07456 11.0449C1.34548 12.0155 1.83291 12.9121 2.50021 13.6672"
        stroke="#344054"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12762_74566">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.0839844)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
