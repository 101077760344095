<template>
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_17619_294202)">
      <path
        d="M24.3069 10.9556V2.85334C24.3069 2.43743 24.1417 2.03856 23.8476 1.74447C23.5535 1.45037 23.1546 1.28516 22.7387 1.28516H2.35236C1.93645 1.28516 1.53758 1.45037 1.24349 1.74447C0.949398 2.03856 0.78418 2.43743 0.78418 2.85334V16.967C0.78418 17.3829 0.949398 17.7818 1.24349 18.0758C1.53758 18.3699 1.93645 18.5352 2.35236 18.5352H8.10236"
        stroke=""
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.62891 6.51243C5.62891 6.82133 5.68975 7.12721 5.80796 7.4126C5.92618 7.698 6.09944 7.95731 6.31787 8.17574C6.5363 8.39417 6.79561 8.56743 7.081 8.68565C7.36639 8.80386 7.67227 8.8647 7.98118 8.8647C8.29008 8.8647 8.59596 8.80386 8.88135 8.68565C9.16675 8.56743 9.42606 8.39417 9.64449 8.17574C9.86292 7.95731 10.0362 7.698 10.1544 7.4126C10.2726 7.12721 10.3335 6.82133 10.3335 6.51243C10.3335 5.88857 10.0856 5.29026 9.64449 4.84912C9.20335 4.40798 8.60504 4.16016 7.98118 4.16016C7.35732 4.16016 6.75901 4.40798 6.31787 4.84912C5.87673 5.29026 5.62891 5.88857 5.62891 6.51243Z"
        stroke=""
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.53076 13.9943H3.8623C3.86218 13.18 4.10322 12.3834 4.55583 11.7022C5.00843 11.0211 5.6529 10.4851 6.41011 10.16C7.16732 9.83499 8.00431 9.73505 8.81831 9.87248C9.63231 10.0099 10.3879 10.3787 10.9923 10.9337"
        stroke=""
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8975 5.20312H19.602"
        stroke=""
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5908 14.6147C13.5908 15.8624 14.0865 17.059 14.9687 17.9413C15.851 18.8236 17.0476 19.3192 18.2954 19.3192C19.5431 19.3192 20.7397 18.8236 21.622 17.9413C22.5043 17.059 22.9999 15.8624 22.9999 14.6147C22.9999 13.367 22.5043 12.1704 21.622 11.2881C20.7397 10.4058 19.5431 9.91016 18.2954 9.91016C17.0476 9.91016 15.851 10.4058 14.9687 11.2881C14.0865 12.1704 13.5908 13.367 13.5908 14.6147Z"
        stroke=""
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.8415 13.0469L17.6586 15.9579C17.6141 16.0172 17.5583 16.0671 17.4945 16.1048C17.4306 16.1425 17.36 16.1673 17.2866 16.1777C17.2132 16.1881 17.1384 16.1839 17.0666 16.1654C16.9948 16.1469 16.9274 16.1144 16.8681 16.0698C16.8471 16.054 16.8273 16.0367 16.8088 16.018L15.6816 14.8909"
        stroke=""
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_17619_294202">
        <rect
          width="25.0909"
          height="23"
          fill=""
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
