import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
    getAllProjectReferences,
    getSingleProjectReference,
    projectReferenceApproval,
    getSelectedProjects,
    getProjectByCostCenter,
    referenceAdvanceSearch,
    getReferenceAdvanceSearchHistory

} from "@/services/endpoints";
import {removeDuplicates} from "@/services/helpers";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    appLoading: false,
    allProjectReferences: [],
    currentProjectReferences: {},
    currentProjectReferencesId: null,
    recentChanges: [],
    headers:[
      { label: "Cost Center", key: "cost_center_number" },
      { label: "Project Title", key: "project_title" },
      { label: "Project Status", key: "project_status" },
    ],
    selectedProject:[],
    selectedProjectIds:[],
    language: 'en',
    selectedTemplate: 'Danida',
    selectedCompanyId: null,
    allAdvanceSearchHistory: []

  },
  mutations: {
    SET_PROJECT_REFERENCES_TO_STATE(state: any, payload: any) {
      state.allProjectReferences = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_HEADER_TO_STATE(state: any, payload: any) {
      // const data = removeDuplicates([...state.headers, payload], "label");
      state.headers = payload;
    },
    REMOVE_HEADER_FROM_STATE(state:any, key:any) {
      state.headers = state.headers.filter((header:any) => header.key !== key);
    },
    SET_CURRENT_PROJECT_REFERENCES_TO_STATE(state: any, payload: any) {
      state.currentProjectReferences = payload;
    },
    SET_CURRENT_PROJECT_REFERENCES_ID_TO_STATE(state: any, payload: any) {
      state.currentProjectReferencesId = payload;
    },
    SET_RECENT_CHANGES_TO_STATE(state: any, payload: any) {
      state.recentChanges = payload;
    },
    SET_SELECTED_PROJECT_TO_STATE(state: any, payload: any) {
      state.selectedProject = payload;
    },
    SET_SELECTED_PROJECT_IDS_TO_STATE(state: any, payload: any) {
      state.selectedProjectIds = payload;
    },
    SET_COST_CENTER_PROJECT_TO_STATE(state: any, payload: any) {
      const data = removeDuplicates([...state.selectedProject, ...payload], "project_title");
      state.selectedProject = data;
      
    },
    SET_LANGUAGE_TO_STATE(state: any, payload: any) {
      state.language = payload;
    }, 
    SET_SELECTED_TEMPLATE_TO_STATE(state: any, payload: any){
      state.selectedTemplate = payload
    },
    SET_SELECTED_COMPANY_ID_TO_STATE(state: any, payload: any){
      state.selectedCompanyId = payload
    },
    SET_ADVANCE_SEARCH_HISTORY_TO_STATE(state: any, payload: any){
      state.allAdvanceSearchHistory = payload
    }

  },
  getters: {
    allProjectReferences: (state: any) => {
      return state?.allProjectReferences;
    },
    appLoading: (state: any) => {
      return state.appLoading;
    },
    currentPage: (state: any) => {
      return state.currentPage;
    },
    totalItems: (state: any) => {
      return state.totalPages;
    },
    tableHeaders: (state: any) => {
      return state?.headers;
    },
    getCurrentProjectReferences: (state: any) => {
      return state.currentProjectReferences;
    },
    currentReferenceId: (state: any) => {
      return state.currentProjectReferencesId;
    },
    recentChanges: (state: any) => {
      const data = state.recentChanges;
      return data;
    },
    selectedProjects: (state: any) => {
      return state.selectedProject;
    },
    selectedProjectIDs: (state: any) => {
      return state.selectedProjectIds;
    },
    getLanguage: (state: any) => {
      return state.language;
    },
    getSelectedTemplate(state: any){
      return state.selectedTemplate
    },
    getSelectedCompanyId(state: any){
      return state.selectedCompanyId
    },
    getAdvanceSearchHistory(state: any){
      return state.allAdvanceSearchHistory
    }
   
  },
  actions: {
    getAllProjectReferences(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllProjectReferences}${payload.page_number}/?search=${payload.search || ''}&project_manager=${payload.project_manager || ''}&controller=${payload.controller || ''}&country=${payload.country || ''}&year=${payload.year || ''}&approval_status=${payload.approval_status || ''}`
        )
        .then((response: any) => {
         
          if (response.status === 200) {
              context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_PROJECT_REFERENCES_TO_STATE", response.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })

        .catch((error: any) => {
          // console.log(error.response, "hospitality package")
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            // this.$router.push({ name: "accessDenied" });
          }
        });
    },

    getSingleProjectReference(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getSingleProjectReference}${context.getters.currentReferenceId}/`)
        .then((response: any) => {
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_CURRENT_PROJECT_REFERENCES_TO_STATE", response.data);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    projectReferenceApproval(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
     
      Api()
        .get(
          `${projectReferenceApproval}${context.getters.currentReferenceId}/`
        )
        .then((response: any) => {

          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_RECENT_CHANGES_TO_STATE", response.data.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch((error: any) => {
          // console.log(error.response, "hospitality package")
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          
          }
        });
    },
    
    getSelectedProjects(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .post(`${getSelectedProjects}`, { project_ids: context.getters.selectedProjectIDs })
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_SELECTED_PROJECT_TO_STATE", response.data.data);
          }
        })
        .catch((error: any) => {
        
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
       
          }
        });
        
    },
    getProjectByCostCenter(context: ActionContext<any, any>, payload: any) {
      // if (payload.load_page) {
      //   context.commit("SET_APP_LOADING_TO_STATE", true);
      // }
      Api()
        .get(`${getProjectByCostCenter}?cost_center_number=${payload.cost_center}`)
        .then((response) => {
        
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_COST_CENTER_PROJECT_TO_STATE", response);
          }
        })
        .catch((error: any) => {
        
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
        
    },

    referenceAdvanceSearch(context: ActionContext<any, any>, payload: any) {
     
      Api()
        .post(`${referenceAdvanceSearch}`, payload)
        .then((response) => {
       
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_PROJECT_REFERENCES_TO_STATE", response.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getReferenceAdvanceSearchHistory(context: ActionContext<any, any>) {
     
      Api()
        .get(`${getReferenceAdvanceSearchHistory}`)
        .then((response) => {
         
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_ADVANCE_SEARCH_HISTORY_TO_STATE", response.data.data);
           
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
