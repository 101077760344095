import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getAllHospitalityPackage,
  getSingleHospitalityPackage,
  searchHospitalityPackages,
} from "@/services/endpoints";
// import router from '@/router/index';
// import router from "./router";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    appLoading: false,
    allHospitalityPackage: [],
    currentHospitalityPackage: {},
    currentHospitalityPackageId: null,
    hospitalityPackageUpdate: {},
    entertainmentRequestForm: {},

    headers: [
      { key: "cost_center", label: "Cost Center" },
      { key: "requester", label: "Requester" },
      { key: "requestee", label: "Requestee" },
      { key: "number_of_people", label: "No. of Invitees", type: "digit" },
      { key: "currency", label: "Currency" },
      { key: "amount_before_tax", label: "Amt. before tax", type: "currency" },
      { key: "amount_after_tax", label: "Amt. after tax", type: "currency" },
      { key: "tips_amount", label: "Tips Amount", type: "currency" },
      { key: "payment_date", label: "Payment Date" },
      { key: "status", label: "Status" },
    ],
    canNotSigned: "",
  },
  mutations: {
    SET_CAN_NOT_SIGNED(state: any, payload: any) {
      state.canNotSigned = payload;
    },
    SET_HOSPITALITY_PACKAGE_TO_STATE(state: any, payload: any) {
      state.allHospitalityPackage = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_HOSPITALITY_PACKAGE_TO_STATE(state: any, payload: any) {
      state.currentHospitalityPackage = payload;
    },
    SET_CURRENT_HOSPITALITY_PACKAGE_ID_TO_STATE(state: any, payload: any) {
      state.currentHospitalityPackageId = payload;
    },

    SET_HOSPITALITY_PACKAGE_UPDATE(state: any, payload: any) {
      state.hospitalityPackageUpdate = {
        ...state.hospitalityPackageUpdate,
        ...payload,
      };
    },
    SET_ENTERTAINMENT_REQUEST_FORM(state: any, payload: any) {
      state.entertainmentRequestForm = {
        ...state.entertainmentRequestForm,
        ...payload,
      };
    },
  },
  getters: {
    allHospitalityPackages(state: any) {
      return state.allHospitalityPackage;
    },
    headers(state: any) {
      return state.headers;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    currentPackage(state: any) {
      return state.currentHospitalityPackage;
    },
    currentHospitalityPackageId(state: any) {
      return state.currentHospitalityPackageId;
    },

    hospitalityPackageUpdate(state: any) {
      return state.hospitalityPackageUpdate;
    },
    getArrayOfObjects(state: any, key: string, value: any) {
      return Object.entries(state.currentHospitalityPackage)?.map(
        ([key, value]) => ({ [key]: value })
      );
    },
    entertainmentRequestForm(state: any) {
      return state.entertainmentRequestForm;
    },
    getCannotSigned(state: any) {
      return state.canNotSigned;
    },
  },
  actions: {
    getAllHospitalityPackage(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllHospitalityPackage}${payload.type}/${payload.page_number}`
        )
        .then((response: any) => {
          
          if (response.data.status === "success") {
            context.commit(
              "SET_HOSPITALITY_PACKAGE_TO_STATE",
              response.data.data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })

        .catch((error: any) => {
          // console.log(error.response, "hospitality package")
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            // this.$router.push({ name: "accessDenied" });
          }
        });
    },

    searchHospitalityPackages(context: ActionContext<any, any>, payload: any) {
      Api()
        .post(
          `${searchHospitalityPackages}${context.getters.isCurrentPage}/`,
          payload
        )
        .then((response) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_HOSPITALITY_PACKAGE_TO_STATE",
              response.data.data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },
    getSingleHospitalityPackage(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getSingleHospitalityPackage}${context.getters.currentHospitalityPackageId}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_HOSPITALITY_PACKAGE_TO_STATE",
              response.data.data
            );
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
