<template>
  <div>
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8335 11.5332C9.19138 12.0116 9.64796 12.4075 10.1723 12.694C10.6966 12.9804 11.2764 13.1508 11.8724 13.1935C12.4683 13.2361 13.0665 13.1501 13.6263 12.9413C14.1861 12.7325 14.6944 12.4057 15.1168 11.9832L17.6168 9.48317C18.3758 8.69733 18.7958 7.64482 18.7863 6.55234C18.7768 5.45985 18.3386 4.4148 17.5661 3.64226C16.7935 2.86973 15.7485 2.43153 14.656 2.42203C13.5635 2.41254 12.511 2.83252 11.7252 3.59151L10.2918 5.01651M12.1668 9.86651C11.809 9.38807 11.3524 8.99219 10.828 8.70572C10.3037 8.41926 9.72391 8.24891 9.12796 8.20623C8.532 8.16355 7.93384 8.24953 7.37405 8.45836C6.81425 8.66718 6.3059 8.99395 5.8835 9.41651L3.3835 11.9165C2.62451 12.7023 2.20453 13.7549 2.21402 14.8473C2.22352 15.9398 2.66172 16.9849 3.43426 17.7574C4.20679 18.53 5.25184 18.9682 6.34433 18.9776C7.43681 18.9871 8.48932 18.5672 9.27517 17.8082L10.7002 16.3832"
        stroke="#A2544F"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<style scoped>
svg {
  /* stroke: currentColor !important; */
  font-size: 15px;
}
</style>
