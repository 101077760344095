import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getPmLocalOverview,
  getYearlyOverview,
  getMonthlyOverview,
} from "@/services/endpoints";
// import moment from "moment";
import { formatAmount, formatDate } from "@/services/helpers";
export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    allExpenses: [],
    addExpense: false,
    allOverviews: [],
    appLoading: false,
    totalsOverview: [],
    defaultCurrency: null,
    overViewStartDate: null,
    overViewEndDate: null,
    allBalanceInfoData: [],
  },
  mutations: {
    SET_CHEQUES_TO_STATE(state: any, payload: any) {
      state.allExpenses = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_TOTAL_DATA_TO_STATE(state: any, payload: any) {
      state.totalData = payload;
    },
    SET_ADD_EXPENSE_TO_STATE(state: any, payload: any) {
      state.addExpense = payload;
    },
    SET_ALL_OVERVIEWS_TO_STATE(state: any, payload: any) {
      state.allOverviews = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_TOTALS_OVERVIEW_TO_STATE(state: any, payload: any) {
      state.totalsOverview = payload;
    },
    SET_DEFAULT_CURRENCY_TO_STATE(state: any, payload: any) {
      state.defaultCurrency = payload;
    },
    SET_OVERVIEW_START_DATE_TO_STATE(state: any, payload: any) {
      state.overViewStartDate = payload;
    },
    SET_OVERVIEW_END_DATE_TO_STATE(state: any, payload: any) {
      state.overViewEndDate = payload;
    },
    SET_ALL_BALANCE_INFO_DATA_TO_STATE(state: any, payload: any) {
      state.allBalanceInfoData = payload;
    },
  },
  getters: {
    isAllExpenses(state: any) {
      return state.allExpenses;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isTotalData(state: any) {
      return state.totalData;
    },
    isAddExpense(state: any) {
      return state.addExpense;
    },
    isAllOverviews(state: any) {
      return state.allOverviews;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isTotalsOverview(state: any) {
      return state.totalsOverview;
    },
    isDefaultCurrency(state: any) {
      return state.defaultCurrency;
    },
    isOverViewStartDate(state: any) {
      return state.overViewStartDate;
    },
    isOverViewEndDate(state: any) {
      return state.overViewEndDate;
    },
    isAllBalanceInfoData(state: any) {
      return state.allBalanceInfoData;
    },

    // isIndividualBalanceInfoData(state: any) {
    //   return state.individualBalanceInfoData;
    // }
  },
  actions: {
    getAllPmLocalOverviews(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .post(
          `${getPmLocalOverview}${payload.projectId}/${payload.foreignEstablishmentId}/`,
          { currency: payload.currency }
        )
        .then((response: any) => {
          if (response) {
            context.commit(
              "SET_OVERVIEW_START_DATE_TO_STATE",
              response.data?.data?.project_details?.project_start_date
            );
            context.commit(
              "SET_OVERVIEW_END_DATE_TO_STATE",
              response.data?.data?.project_details?.project_end_date
            );

            const transformedData = response.data?.data?.budget_overview?.map(
              (item: any) => {
                return {
                  "budget item": item.budget__budget_item__position,
                  quantity: item.budget_quantity,
                  "unit amt": item.amount_per_unit,
                  "plan total": formatAmount(item.plan_total),
                  "plan monthly": formatAmount(item.plan_monthly),
                  yearly_data: item.yearly_data,
                  total: formatAmount(item.totals),
                  "remaining amt": formatAmount(item.remaining_amount),
                };
              }
            );
            const transformedBottomData = response.data?.data?.bottom_data?.map(
              (item: any) => {
                return {
                  "budget item": item.budget__budget_item__position,
                  quantity: item.budget_quantity,
                  "unit amt": item.amount_per_unit,
                  "plan total":
                    item.budget__budget_item__position === "Liquidity" ||
                    item.budget__budget_item__position === "Funds Transferred"
                      ? " "
                      : formatAmount(item.plan_total),
                  "plan monthly":
                    item.budget__budget_item__position === "Liquidity" ||
                    item.budget__budget_item__position === "Funds Transferred"
                      ? " "
                      : formatAmount(item.plan_monthly),
                  yearly_data: item.yearly_data,
                  total:
                    item.budget__budget_item__position === "Liquidity" ||
                    item.budget__budget_item__position === "Funds Transferred"
                      ? " "
                      : formatAmount(item.totals),
                  "remaining amt":
                    item.budget__budget_item__position === "Liquidity" ||
                    item.budget__budget_item__position === "Funds Transferred"
                      ? " "
                      : formatAmount(item.remaining_amount),
                };
              }
            );

            context.commit("SET_ALL_OVERVIEWS_TO_STATE", transformedData);
            context.commit(
              "SET_TOTALS_OVERVIEW_TO_STATE",
              transformedBottomData
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getPmLocalYearOverviews(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .post(
          `${getYearlyOverview}${payload.projectId}/${payload.foreignEstablishmentId}/`,
          {
            year: payload.year,
            expense_type: payload.expense_type,
            currency: payload.currency,
          }
        )
        .then((response: any) => {
          if (response) {
            context.commit("SET_ALL_BALANCE_INFO_DATA_TO_STATE", response.data);
            const transformedYearData = response.data?.overview_data?.map(
              (item: any) => {
                return {
                  "budget item": item.budget__budget_item__position,
                  january: formatAmount(item.january),
                  february: formatAmount(item.february),
                  march: formatAmount(item.march),
                  april: formatAmount(item.april),
                  may: formatAmount(item.may),
                  june: formatAmount(item.june),
                  july: formatAmount(item.july),
                  august: formatAmount(item.august),
                  september: formatAmount(item.september),
                  october: formatAmount(item.october),
                  november: formatAmount(item.november),
                  december: formatAmount(item.december),
                  "year's total": formatAmount(item.yearly_total),
                };
              }
            );
            const transformedYearBottomData = response.data?.bottom_data?.map(
              (item: any) => {
                return {
                  "budget item": item.budget__budget_item__position,
                  january: formatAmount(item.january),
                  february: formatAmount(item.february),
                  march: formatAmount(item.march),
                  april: formatAmount(item.april),
                  may: formatAmount(item.may),
                  june: formatAmount(item.june),
                  july: formatAmount(item.july),
                  august: formatAmount(item.august),
                  september: formatAmount(item.september),
                  october: formatAmount(item.october),
                  november: formatAmount(item.november),
                  december: formatAmount(item.december),
                  "year's total": formatAmount(item.yearly_total),
                };
              }
            );
            context.commit("SET_ALL_OVERVIEWS_TO_STATE", transformedYearData);
            context.commit(
              "SET_TOTALS_OVERVIEW_TO_STATE",
              transformedYearBottomData
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getPmLocalMonthlyOverviews(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .post(
          `${getMonthlyOverview}${payload.projectId}/${payload.foreignEstablishmentId}/`,
          {
            month: payload.month,
            year: payload.year,
            expense_type: payload.expense_type,
            currency: payload.currency,
          }
        )
        .then((response) => {
          if (response) {
            context.commit("SET_ALL_BALANCE_INFO_DATA_TO_STATE", response.data);
            const transformedMonthData = response.data?.overview_data?.map(
              (item: any) => {
                const formattedDocumentDate = formatDate(item.document_date);
                const formattedPaymentDate = formatDate(item.payment_date)
                return {
                  "budget item": item.budget__budget_item__position,
                  "doc. date": item.document_date
                    ? `${formattedDocumentDate}`
                    : "N/A",
                  "pay date": item.payment_date
                    ? `${formattedPaymentDate}`
                    : "N/A",
                  supplier: item.supplier,
                  currency: item.currency__currency,
                  files: item.file_title,
                  "amt excl. tax": formatAmount(item.total_amount_not_taxed),
                  "amt incl. tax": formatAmount(item.total_amount_taxed),
                  "amt(eur) excl. tax": formatAmount(
                    item.total_amount_not_taxed_eur
                  ),
                  "amt(eur) incl. tax": formatAmount(
                    item.total_amount_taxed_eur
                  ),
                };
              }
            );
            const transformedMonthBottomData = response.data?.bottom_data?.map(
              (item: any) => {
                return {
                  "budget item": item.budget__budget_item__position,
                  "amt excl. tax": formatAmount(item.total_amount_not_taxed),
                  "amt incl. tax": formatAmount(item.total_amount_taxed),
                  "amt(eur) excl. tax": formatAmount(
                    item.total_amount_not_taxed_eur
                  ),
                  "amt(eur) incl. tax": formatAmount(
                    item.total_amount_taxed_eur
                  ),
                };
              }
            );
            context.commit("SET_ALL_OVERVIEWS_TO_STATE", transformedMonthData);
            context.commit(
              "SET_TOTALS_OVERVIEW_TO_STATE",
              transformedMonthBottomData
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
