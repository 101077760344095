import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getAccountNumbersPaginated,
  manageAccountNumbers,
  getVatRates,
  manageVatRates,
} from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    appLoading: false,
    allAccountNumbers: [],
    allVatRates: [],
    currentVatRate: {},
    currentAccountNumber: {},
    currentAccountNumberId: "",
  },
  mutations: {
    SET_VAT_RATES_TO_STATE(state: any, payload: any) {
      state.allVatRates = payload;
    },
    SET_CURRENT_VAT_RATE_TO_STATE(state: any, payload: any) {
      state.currentVatRate = payload;
    },
    SET_ACCOUNT_NUMBERS_TO_STATE(state: any, payload: any) {
      state.allAccountNumbers = payload;
    },
    SET_CURRENT_ACCOUNT_NUMBER_TO_STATE(state: any, payload: any) {
      state.currentAccountNumber = payload;
    },
    SET_CURRENT_ACCOUNT_NUMBER_ID_TO_STATE(state: any, payload: any) {
      state.currentAccountNumberId = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_TOTAL_DATA_TO_STATE(state: any, payload: any) {
      state.totalData = payload;
    },
  },
  getters: {
    allVatRates(state: any) {
      return state.allVatRates;
    },
    currentVatRate(state: any) {
      return state.currentVatRate;
    },
    allAccountNumbers(state: any) {
      return state.allAccountNumbers;
    },
    currentAccountNumber(state: any) {
      return state.currentAccountNumber;
    },
    currentAccountNumberId(state: any) {
      return state.currentAccountNumberId;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    getCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },

    isTotalData(state: any) {
      return state.totalData;
    },
  },
  actions: {
    getAccountNumbersPaginated(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      const endpoint =
        payload.account_type === "account"
          ? `${getAccountNumbersPaginated}page_number=${payload.page_number}/`
          : `${getAccountNumbersPaginated}account_type=Entertainment_Account&page_number=${payload.page_number}/`;
      Api()
        .get(`${endpoint}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_ACCOUNT_NUMBERS_TO_STATE",
              response.data.data.data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.data.total_data
            );
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getVatRates(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getVatRates}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_VAT_RATES_TO_STATE", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getSingleVatRate(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${manageVatRates}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_CURRENT_VAT_RATE_TO_STATE", responseData);
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getSingleAccount(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${manageAccountNumbers}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_CURRENT_ACCOUNT_NUMBER_TO_STATE", responseData);
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
